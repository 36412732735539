import {
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAIL
} from '../actions/types';

const initialState = {
    categorias: null
};

export default function Categorias(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                categorias: payload.categorias
            }
        case GET_CATEGORIES_FAIL:
            return {
                ...state,
                categorias: null
            }
        default:
            return state
    }
}