import React, { useState, useEffect } from "react";
import { DateRangePicker } from 'react-date-range';
import { es } from "date-fns/locale";
import '../../css/MultiDatePicker.css';
import 'react-date-range/dist/styles.css'; // archivo principal de estilos
import 'react-date-range/dist/theme/default.css'; // archivo de estilos de tema
// import flecha from '../../images/Vector 13.png'

const MultiDatePicker = ({ onChange, fechaCreacion, fechaResolucion }) => {

    const [date, setDate] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const today = new Date();
    today.setHours(0, 0, 0, 0);  // ajusta el dia de hoy a medianoche
    useEffect(() => {
        if (fechaCreacion) {
            const startDate = new Date(fechaCreacion) > today ? new Date(fechaCreacion) : today;
            setDate({
                startDate: startDate,
                endDate: fechaResolucion ? new Date(fechaResolucion) : null,
                key: 'selection',
            });
        }
    }, [fechaCreacion, fechaResolucion]);

    const handleChange = (ranges) => {
        const newDate = {
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate,
            key: 'selection',
        };
        
        if (!newDate.endDate || newDate.endDate.getTime() === newDate.startDate.getTime()) {
            newDate.endDate = newDate.startDate;
        }
        
        setDate(newDate);
        onChange(newDate);
    };
    return (
        <div className="calendario">
           <DateRangePicker
                className="dateRange"
                ranges={[date]}
                onChange={handleChange}
                locale={es}
                showDateDisplay={false}
                showMonthAndYearPickers={true}
                showMonthArrow={true}
                minDate={today}
                selectsRange={true}
                moveRangeOnFirstSelection={false}
            />
        </div>
    );
};

export default MultiDatePicker;
