import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { get_productos_admin, deleteProducto } from '../redux/actions/productos';
import AdminCategoryList from '../components/AdminCategoryList';
import AdminProductCard from './AdminProductCard';
import NuevoProducto from './NuevoProducto';
import '../css/AdminProductList.css';


const AdminProductList = ({ productos, get_productos_admin, deleteProducto, searchTerm }) => {
    const [productosFiltrados, setProductosFiltrados] = useState([]);
    const [showNuevoProducto, setShowNuevoProducto] = useState(false);
    const [openProductId, setOpenProductId] = useState(null);

    useEffect(() => {
        get_productos_admin();
    }, [get_productos_admin]);

    useEffect(() => {
        if (productos) {
            setProductosFiltrados(productos);
        }
    }, [productos]);

    useEffect(() => {
        if (productos && searchTerm) {
            const filteredProducts = productos.filter(producto =>
                producto && producto.equipo && producto.equipo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (producto && producto.id && producto.id.toString().includes(searchTerm.toLowerCase()))
            );
            setProductosFiltrados(filteredProducts);
        } else {
            setProductosFiltrados(productos || []);
        }
    }, [productos, searchTerm]);

    const handleCategoryChange = useCallback((categoria, seccion = null) => {
        if (categoria === "Todo") {
            setProductosFiltrados(productos); // Show all products
        } else {
            const filteredProducts = productos.filter(producto =>
                producto.categoria === categoria && (!seccion || producto.seccion === seccion)
            );
            setProductosFiltrados(filteredProducts);
        }
    }, [productos]);



    const handleNuevoClick = () => {
        setShowNuevoProducto(true);
    };

    const handleCloseNuevoProducto = () => {
        setShowNuevoProducto(false);
    };

    const handleProductAdded = (newProduct) => {
        get_productos_admin(); // Refresh the product list
    };

    const handleCardClick = (productId) => {
        setOpenProductId(productId);
    };

    const handleCloseProductModal = () => {
        setOpenProductId(null);
    };


    return (
        <main className='admin-productos'>
            <div className="admin-product-container">
                <AdminCategoryList 
                    mostrarPorCategoria={handleCategoryChange}
                    onNuevoClick={handleNuevoClick}
                    productos={productos}
                />
                <div className="card-container-wrapper">
                    <div className="card-container">
                        {productosFiltrados.map(producto => (
                            <AdminProductCard
                                key={producto.id}
                                producto={producto}
                                isOpen={openProductId === producto.id}
                                onOpen={() => handleCardClick(producto.id)}
                                onClose={handleCloseProductModal}
                                deleteProducto={deleteProducto}
                            />
                        ))}
                    </div>
                </div>
            </div>
            {showNuevoProducto && (
                <NuevoProducto 
                    onClose={handleCloseNuevoProducto}
                    onProductAdded={handleProductAdded}
                />
            )}
        </main>
    );
};


const mapStateToProps = state => ({
    productos: state.Productos.productos,
    searchTerm: state.Productos.searchTerm,
});

export default connect(mapStateToProps, {
    get_productos_admin,
    deleteProducto 
})(AdminProductList);