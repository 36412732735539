// Authentication
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const REMOVE_AUTH_LOADING = 'REMOVE_AUTH_LOADING';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const REFRESH_FAIL = 'REFRESH_FAIL';
export const LOGOUT = 'LOGOUT';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_CONFIRM_SUCCESS = 'RESET_PASSWORD_CONFIRM_SUCCESS';
export const RESET_PASSWORD_CONFIRM_FAIL = 'RESET_PASSWORD_CONFIRM_FAIL';
//USER
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

//PEDIDOS
export const GET_PEDIDOS_SUCCESS = 'GET_PEDIDOS_SUCCESS';
export const GET_PEDIDOS_FAIL = 'GET_PEDIDOS_FAIL';

//PRODUCTOS
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL';

export const GET_PRODUCTOS_BY_PEDIDO_SUCCESS = 'GET_PRODUCTOS_BY_PEDIDO_SUCCESS';
export const GET_PRODUCTOS_BY_PEDIDO_FAIL = 'GET_PRODUCTOS_BY_PEDIDO_FAIL';

export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
//CATEGORIAS
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';

//CARRITO
export const GET_CARRITO_SUCCESS = 'GET_CARRITO_SUCCESS';
export const GET_CARRITO_FAIL = 'GET_CARRITO_FAIL';
export const ADD_ITEM_CARRITO_SUCCESS = 'ADD_ITEM_CARRITO_SUCCESS'
export const ADD_ITEM_CARRITO_FAIL = 'ADD_ITEM_CARRITO_FAIL'
export const DELETE_ITEM_CARRITO_SUCCESS = 'DELETE_ITEM_CARRITO_SUCCESS'
export const DELETE_ITEM_CARRITO_FAIL = 'DELETE_ITEM_CARRITO_FAIL'

//SOLICITUD
export const ADD_ITEM_SOLICITUD = 'ADD_ITEM_SOLICITUD'
export const DELETE_ITEM_SOLICITUD = 'DELETE_ITEM_SOLICITUD'
export const GET_SOLICITUD_SUCCESS = 'GET_SOLICITUD_SUCCESS';
export const GET_SOLICITUD_FAIL = 'GET_SOLICITUD_FAIL';
export const CREATE_PEDIDO_SUCCESS = 'CREATE_PEDIDO_SUCCESS';
export const CREATE_PEDIDO_FAIL = 'CREATE_PEDIDO_FAIL';
export const SET_FECHA_SUCCESS = 'SET_FECHA_SUCCESS';
export const SET_FECHA_FAIL = 'SET_FECHA_FAIL';
export const SET_HORARIOS_SUCCESS = 'SET_HORARIOS_SUCCESS'
export const SET_HORARIOS_FAIL = 'SET_HORARIOS_FAIL';
export const GET_CARRITOS_SUCCESS = 'GET_CARRITOS_SUCCESS'
export const GET_CARRITOS_FAIL = 'GET_CARRITOS_FAIL'
export const SET_TALLER_SUCCESS = 'SET_TALLER_SUCESS'
export const SET_TALLER_FAIL = 'SET_TALLER_FAIL'
export const SET_DESCRIPCION_SUCCESS = 'SET_DESCRIPCION_SUCCESS'
export const SET_DESCRIPCION_FAIL = 'SET_DESCRIPCION_FAIL'
export const SET_USUARIO_SUCCESS = 'SET_USUARIO_SUCCESS'
export const SET_USUARIO_FAIL = 'SET_USUARIO_FAIL'
export const RESET_SOLICITUD = 'RESET_SOLICITUD'

//Resoluciones
export const PROCESAR_PEDIDO_SUCCESS = 'PROCESAR_PEDIDO_SUCCESS';
export const PROCESAR_PEDIDO_FAIL = 'PROCESAR_PEDIDO_FAIL';

//USER
export const GET_TALLERES_SUCCESS = 'GET_TALLERES_SUCCESS';
export const GET_TALLERES_FAIL = 'GET_TALLERES_FAIL';
export const CREATE_TALLER_SUCCESS = 'CREATE_TALLER_SUCCESS';
export const CREATE_TALLER_FAIL = 'CREATE_TALLER_FAIL';
export const UPDATE_TALLER_SUCCESS = 'UPDATE_TALLER_SUCCESS';
export const UPDATE_TALLER_FAIL = 'UPDATE_TALLER_FAIL';
export const DELETE_TALLER_SUCCESS = 'DELETE_TALLER_SUCCESS';
export const DELETE_TALLER_FAIL = 'DELETE_TALLER_FAIL';