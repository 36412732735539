import {
    GET_CARRITO_SUCCESS,
    GET_CARRITO_FAIL
} from '../actions/types';

const initialState = {
    carrito: null
};

export default function Carrito(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_CARRITO_SUCCESS:
            return {
                ...state,
                carrito: payload.carrito //Recordar que este .carrito proviene del Response en la vista
            }
        case GET_CARRITO_FAIL:
            return {
                ...state,
                carrito: null
            }
        default:
            return state
    }
}