import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { updateProducto } from '../redux/actions/productos';
import { get_categorias } from '../redux/actions/categorias';  
import CreatableSelect from 'react-select/creatable';  
import '../css/AdminProductModal.css';

import { v4 as uuidv4 } from 'uuid';

const AdminProductModal = ({ producto, onClose, updateProducto, categorias, get_categorias }) => {
    const [editedProduct, setEditedProduct] = useState({ ...producto, newImages: [], removedImages: [] });
    const [selectedCategory, setSelectedCategory] = useState(
        producto.categoria ? { value: producto.categoria, label: producto.categoria } : null
    );
    const [sections, setSections] = useState([]);
    const [selectedSection, setSelectedSection] = useState(
        producto.seccion ? { value: producto.seccion, label: producto.seccion } : null
    );
    const [previewUrls, setPreviewUrls] = useState([]);
    const [images, setImages] = useState([]);
    const [currentImageProgress, setCurrentImageProgress] = useState(0);
    const [isProcessingImage, setIsProcessingImage] = useState(false);

    useEffect(() => {
        get_categorias();  
    }, [get_categorias]);

    useEffect(() => {
        if (selectedCategory) {
            const category = categorias.find(cat => cat.nombre === selectedCategory.value);
            if (category) {
                const sectionOptions = category.secciones.map(sec => ({
                    value: sec.nombre,
                    label: sec.nombre
                }));
                setSections(sectionOptions);

                // Set the initial section value
                const initialSection = sectionOptions.find(sec => sec.value === editedProduct.seccion);
                setSelectedSection(initialSection || null);
            }
        } else {
            setSections([]);
            setSelectedSection(null);
        }
    }, [selectedCategory, categorias, editedProduct.seccion]);
    
    const getImageUrl = (imagePath) => {
        if (!imagePath) return null;
        return `${process.env.REACT_APP_API_URL}${imagePath}`;
    };
    useEffect(() => {
        // Initialize previewUrls with existing product images
        if (producto.images) {
            
            setPreviewUrls(producto.images.map(image => {
                const url = getImageUrl(image.image);
               
                return url;
            }));
        }
    }, [producto]);

    const handleCategoryChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'create-option') {
            // New category created
            setEditedProduct(prev => ({
                ...prev,
                categoria: newValue.value  // Save the new category
            }));
        } else {
            // Existing category selected
            setSelectedCategory(newValue);
            setEditedProduct(prev => ({
                ...prev,
                categoria: newValue ? newValue.value : null  // Save selected category
            }));
        }
    };


    const handleSectionChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'create-option') {
            // New section created
            setSections(prevSections => [
                ...prevSections,
                { value: newValue.value, label: newValue.value }  // Add the new section to the list
            ]);
            setSelectedSection({ value: newValue.value, label: newValue.value });
            setEditedProduct(prev => ({
                ...prev,
                seccion: newValue.value  // Save the new section
            }));
        } else {
            // Existing section selected
            setSelectedSection(newValue);
            setEditedProduct(prev => ({
                ...prev,
                seccion: newValue ? newValue.value : null  // Save selected section
            }));
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedProduct(prev => ({
            ...prev,
            [name]: value === '' ? null : value
        }));
    };
    
    const customStyles = {
        control: (provided, state) => ({
          ...provided,
          minWidth: '340px',
          maxWidth: '340px',
          boxShadow: state.isFocused ? 'none' : provided.boxShadow,
          borderColor: state.isFocused ? '#ccc' : provided.borderColor,
          borderWidth: '1px',
          '&:hover': {
            borderColor: state.isFocused ? '#ccc' : provided.borderColor
          },
        }),
        input: (provided) => ({
            ...provided,
            border: 'none !important',
            boxShadow: 'none !important',
            outline: 'none !important',
            '& input': {
              border: 'none !important',
              boxShadow: 'none !important',
              outline: 'none !important',
            },
          }),
        placeholder: (provided) => ({
          ...provided,
          color: '#999'
        }),
        menu: (provided) => ({
          ...provided,
          minWidth: '340px'
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? '#ccc' : provided.backgroundColor,
          '&:hover': {
            backgroundColor: '#f1f3f5'
          },
        }),
      };
     const compressImage = (file, maxSizeMB, maxWidth, maxHeight) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    let width = img.width;
                    let height = img.height;
    
                    if (width > height) {
                        if (width > maxWidth) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width *= maxHeight / height;
                            height = maxHeight;
                        }
                    }
    
                    const canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
    
                    const compressAndCheck = (quality) => {
                        setCurrentImageProgress(Math.min(100, 100 - (quality * 100)));
                        canvas.toBlob(
                            (blob) => {
                                if (blob.size > maxSizeMB * 1024 * 1024 && quality > 0.1) {
                                    compressAndCheck(quality - 0.1);
                                } else {
                                    const compressedFile = new File([blob], file.name, {
                                        type: 'image/webp',
                                        lastModified: Date.now(),
                                    });
                                    resolve(compressedFile);
                                }
                            },
                            'image/webp',
                            quality
                        );
                    };
    
                    compressAndCheck(0.7);
                };
                img.onerror = reject;
            };
            reader.onerror = reject;
        });
    };
    const handleImageChange = async (e) => {
        const fileInput = e.target;
        const file = fileInput.files[0];
        if (!file) return;
    
        setIsProcessingImage(true);
        setCurrentImageProgress(0);
    
        try {
            const maxSizeMB = 0.5;
            const maxWidth = 500;
            const maxHeight = 667;
    
            const compressedFile = await compressImage(file, maxSizeMB, maxWidth, maxHeight);
            setCurrentImageProgress(100);
    
            const webpFilename = file.name.replace(/\.[^/.]+$/, "") + ".webp";
            const renamedWebp = new File([compressedFile], webpFilename, { type: 'image/webp' });
    
            const previewUrl = URL.createObjectURL(renamedWebp);
    
            const newImage = { id: uuidv4(), file: renamedWebp, previewUrl };
    
            setImages(prev => [...prev, newImage]);
            setPreviewUrls(prev => [...prev, newImage.previewUrl]);
            setEditedProduct(prev => ({ ...prev, newImages: [...prev.newImages, renamedWebp] }));
        } catch (error) {
            console.error('Error compressing image:', error);
            setIsProcessingImage(false);
        } finally {
            setIsProcessingImage(false);
            fileInput.value = '';
        }
    };
    
    
    const handleRemoveImage = (index) => {
        const imageUrl = previewUrls[index];

        setEditedProduct(prev => {
            const newImages = [...prev.newImages];
            const removedImages = [...prev.removedImages];

            if (index < producto.images.length) {
                removedImages.push(producto.images[index].id); // Assuming each image has an `id`
            } else {
                newImages.splice(index - producto.images.length, 1);
            }

            return { ...prev, newImages, removedImages };
        });

        setPreviewUrls(prev => prev.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const formData = new FormData();
    
        Object.keys(editedProduct).forEach(key => {
            if (key !== 'newImages' && key !== 'removedImages') {
                if (editedProduct[key] !== null && editedProduct[key] !== undefined) {
                    formData.append(key, editedProduct[key]);
                }
            }
        });
    
        editedProduct.newImages.forEach((image) => {
            formData.append('new_images', image);
        });
    
        editedProduct.removedImages.forEach((imageId) => {
            formData.append('removed_images', imageId);
        });
    
        try {
            const success = await updateProducto(producto.id, formData);
            if (success) {
                onClose();
            } else {
                console.error('Error updating product');
            }
        } catch (error) {
            console.error('Error updating product:', error);
        }
    };
    

    return (
        <div className="admin-product-modal">
            <div className="admin-product-header">
                <h2>Editar Producto</h2>
                <button className="admin-product-close" onClick={onClose}>&times;</button>
            </div>
            <form className="admin-product-form" onSubmit={handleSubmit}>
                <div className="admin-product-form-group">
                    <label htmlFor="categoria">Categoría:</label>
                    <CreatableSelect
                        id="categoria"
                        isClearable
                        placeholder="Categoría"
                        value={
                            editedProduct.categoria
                                ? { value: editedProduct.categoria, label: editedProduct.categoria }
                                : null
                        }
                        onChange={handleCategoryChange}
                        options={categorias.map(cat => ({
                            value: cat.nombre,
                            label: cat.nombre
                        }))}
                        formatCreateLabel={(inputValue) => `Crear categoría: ${inputValue}`}
                        styles={customStyles}
                    />
                </div>
                <div className="admin-product-form-group">
                    <label htmlFor="seccion">Sección:</label>
                    <CreatableSelect
                        id="seccion"
                        isClearable
                        placeholder="Sección"
                        value={
                            selectedSection
                                ? { value: selectedSection.value, label: selectedSection.label }
                                : null
                        }
                        onChange={handleSectionChange}
                        options={sections}
                        formatCreateLabel={(inputValue) => `Crear sección: ${inputValue}`}
                        styles={customStyles}
                    />
                </div>
                <div className="admin-product-form-group">
                    <label htmlFor="equipo">Nombre:</label>
                    <input id="equipo" name="equipo" value={editedProduct.equipo||'Sin Nombre'} onChange={handleChange} placeholder="Equipo" />
                </div>
                <div className="admin-product-form-group">
                    <label htmlFor="marca">Marca:</label>
                    <input id="marca" name="marca" value={editedProduct.marca||''} onChange={handleChange} placeholder="Marca" />
                </div>
                <div className="admin-product-form-group">
                    <label htmlFor="descripcion">Descripción:</label>
                    <textarea id="descripcion" name="descripcion" value={editedProduct.descripcion||''} onChange={handleChange} placeholder="Descripción" />
                </div>
                <div className="admin-product-form-group">
    <label htmlFor="cantidad">Cantidad: </label>
    <input 
        id="cantidad" 
        type="number" 
        name="cantidad" 
        value={editedProduct.cantidad || ''} 
        onChange={handleChange} 
        placeholder="Cantidad" 
    />
</div>
                <div className="admin-product-form-group">
                    <label htmlFor="estado">Estado:</label>
                    <input id="estado" name="estado" value={editedProduct.estado||''} onChange={handleChange} placeholder="Estado" />
                </div>
                <div className="admin-product-form-group">
    <label htmlFor="fecha_vencimiento">Fecha de Vencimiento:</label>
    <input 
        type="date" 
        id="fecha_vencimiento" 
        name="fecha_vencimiento" 
        value={editedProduct.fecha_vencimiento ? editedProduct.fecha_vencimiento.split('T')[0] : ''} 
        onChange={handleChange} 
        placeholder="Fecha de Vencimiento" 
    />
</div>
                <div className="admin-product-form-group">
                    <label htmlFor="proveedor">Proveedor:</label>
                    <input id="proveedor" name="proveedor" value={editedProduct.proveedor||''} onChange={handleChange} placeholder="Proveedor" />
                </div>
                <div className="admin-product-form-group">
                    <label htmlFor="observacion">Observación:</label>
                    <input id="observacion" name="observacion" value={editedProduct.observacion||''} onChange={handleChange} placeholder="Observación" />
                </div>

                <div className="admin-product-form-group">
                    <label>Imágenes:</label>
                    <div className="image-upload-container">
                    {previewUrls.map((url, index) => (
    <div key={index} className="image-preview">
        <img src={url} alt={`Preview ${url}`} />
        <button type="button" onClick={() => handleRemoveImage(index)}>×</button>
    </div>
                        ))}
                        <label className="image-upload-label">
                            <input type="file" accept="image/*" onChange={handleImageChange} />
                            <span>+</span>
                        </label>
                    </div>
                </div>

                <button className="submitButton"type="submit">Guardar Cambios</button>
            </form>
        </div>
    );
};
const mapStateToProps = (state) => ({
    categorias: state.Categorias.categorias, 
});
export default connect(mapStateToProps, { updateProducto, get_categorias })(AdminProductModal);
