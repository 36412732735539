import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import '../css/Tarjetacarrito.css';
import { get_productos } from '../redux/actions/productos';
import { connect } from 'react-redux';
import CategoryList from '../components/CategoryList';
import ProductCard from './ProductCard';
import { getSolicitud } from '../redux/actions/solicitud';

const Productscarrito = ({
    productos,
    get_productos,
    carrito,
    searchTerm,
    getSolicitud,
    horarios_selected
}) => {
    const [productosFiltrados, setProductosFiltrados] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        
        // Only fetch products if horarios_selected is available
        if (horarios_selected && horarios_selected.length > 0) {
            get_productos(horarios_selected);
        }else{
            get_productos(JSON.parse(sessionStorage.getItem('horarios_selected')));
        }
    }, [get_productos, horarios_selected]);
    

    useEffect(() => {
        if (productos && searchTerm) {
            const filteredProducts = productos.filter(producto =>
                producto && (
                    (producto.equipo && producto.equipo.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (producto.id && producto.id.toString().includes(searchTerm.toLowerCase()))
                )
            );
            setProductosFiltrados(filteredProducts);
        } else {
            setProductosFiltrados(productos || []);
        }
    }, [productos, searchTerm]);

    const handleCategoryChange = useCallback((categoria, seccion = null) => {
        if (categoria === "Todo") {
            setProductosFiltrados(productos || []); // Show all products
        } else {
            const filteredProducts = (productos || []).filter(producto =>
                producto && producto.categoria === categoria && (!seccion || producto.seccion === seccion)
            );
            setProductosFiltrados(filteredProducts);
        }
    }, [productos]);

    return (
        <main className='productos-carrito'>
            
            <CategoryList mostrarPorCategoria={handleCategoryChange} />
            <Link to="/fechas" className="button volver-button">Volver</Link>
            <div className="card-container">
                {productosFiltrados && productosFiltrados.map((producto, index) => (
                    producto && (
                        <ProductCard
                            key={producto.id || index}
                            producto={producto}
                            itemsCarrito={carrito.items}
                        />
                    )
                ))}
            </div>
        </main>
    );
};

const mapStateToProps = state => ({
    productos: state.Productos.productos,
    carrito: state.Solicitud.carrito,
    horarios_selected: state.Solicitud.horarios_selected,
    searchTerm: state.Productos.searchTerm, 
});

export default connect(mapStateToProps, {
    get_productos,
    getSolicitud
})(Productscarrito);