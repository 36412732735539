import { ADD_ITEM_SOLICITUD, DELETE_ITEM_SOLICITUD, CREATE_PEDIDO_SUCCESS, CREATE_PEDIDO_FAIL, GET_SOLICITUD_SUCCESS, GET_SOLICITUD_FAIL, SET_FECHA_SUCCESS, SET_FECHA_FAIL, SET_HORARIOS_SUCCESS, SET_HORARIOS_FAIL, GET_CARRITO_SUCCESS, GET_CARRITO_FAIL, SET_TALLER_SUCCESS, SET_TALLER_FAIL, SET_DESCRIPCION_SUCCESS, SET_DESCRIPCION_FAIL, SET_USUARIO_SUCCESS, SET_USUARIO_FAIL, RESET_SOLICITUD} from '../actions/types';

const initialState = {
    pedido: null,
    usuario: null,
    taller: '',
    descripcion: '',
    fecha_creacion: null,
    fecha_resolucion: null,
    horarios_selected: [],
    estado: null,
    carrito: {}
    
};

export default function Solicitud(state = initialState, action){
    const { type, payload } = action;
    switch (type) {
        case GET_SOLICITUD_SUCCESS:
            return{
                ...state,
                pedido:payload.pedido.id,
                taller:payload.pedido.taller,
                descripcion:payload.pedido.descripcion,
                fecha_creacion:payload.pedido.fecha_creacion,
                fecha_resolucion:payload.pedido.fecha_resolucion,
                horarios_selected:payload.pedido.horarios,
                estado:payload.pedido.estado,
                carrito:payload.pedido.carrito,
                usuario:payload.pedido.usuario

            };
        case GET_SOLICITUD_FAIL:
            return{
                ...state,
                error:'error al obtener solicitud'
            }
        case CREATE_PEDIDO_SUCCESS:
            return {
                ...state,
                pedido: payload,
                error: null
            };
        case CREATE_PEDIDO_FAIL:
            return {
                ...state,
                error: 'Error al crear el pedido'
            };
        case SET_FECHA_SUCCESS:
            return{
                ...state,
                fecha_creacion:payload.fecha_creacion,
                fecha_resolucion:payload.fecha_resolucion,

            }
        case SET_FECHA_FAIL:
            return{
                ...state,
                error:'Error al modificar fecha'
            }
        case SET_HORARIOS_SUCCESS:
            return{
                ...state,
                horarios_selected: payload.horarios_selected
            }
        case SET_HORARIOS_FAIL:
            return{
                ...state,
                error:'Error al modificar horario'
            }
        case GET_CARRITO_SUCCESS:
            return{
                ...state,
                carrito: {
                    ...state.carrito,
                    items: payload.carrito,
                    total: payload.carrito.length
                }
            }
        case GET_CARRITO_FAIL:
            return{
                ...state,
                error:'Error al obtener el carrito de este pedido'
                }
        case ADD_ITEM_SOLICITUD:
            console.log(payload)
            return{
                ...state,
                carrito: payload
            }
        case DELETE_ITEM_SOLICITUD:
            return {
                ...state,
                carrito: action.payload
            }
        case SET_TALLER_SUCCESS:
            return {
                ...state,
                taller: payload.taller
            };
        case SET_TALLER_FAIL:
            return {
                ...state,
                error:'Error al obtener taller'
                };
        case SET_DESCRIPCION_SUCCESS:
            return {
                ...state,
                descripcion: payload.descripcion
            };
        case SET_DESCRIPCION_FAIL:
            return {
                ...state,
                error:'Error al obtener descripcion'
            };
        case SET_USUARIO_SUCCESS:
            return{
                ...state,
                usuario: payload.usuario
            }
        case SET_USUARIO_FAIL:
            return{
                ...state,
                error:'Error al obtener usuario'
            }
        case RESET_SOLICITUD:
            return initialState;
        default:
            return state;
    }
};

