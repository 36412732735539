import Layout from "../../hocs/Layout";
import '../../css/Landingadmin.css';
import { connect } from "react-redux";
import SolicitudPanol from "../../components/SolicitudPanol";




const Landingadmin = () => {
  return (
    <Layout>
      <main className="landingpanol">
       <SolicitudPanol/>
      </main>
    </Layout>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  user: state.Auth.user,
})
export default connect(mapStateToProps, {

})(Landingadmin);