import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux';
import store from './store';
import Login from './containers/pages/Login';
import Singin from './containers/pages/Singin';
import Request from './containers/pages/Request';
import Landing from './containers/pages/Landing';
import SolicitudList from './components/SolicitudList';
import FechaHorario from './containers/pages/FechaHorario';
import Landingadmin from './containers/pages/Landingadmin';
import ComponentDev from './containers/pages/ComponentDev';
import Carrito from './containers/pages/Carrito';
import AgregarProductos from './containers/pages/AgregarProductos';
import ListaProductos from './containers/pages/ListaProductos';
import CambioContrasena from './containers/pages/CambioContrasena';
import GestionarUsuarios from './containers/pages/GestionarUsuarios';
import GestionarTalleres from './containers/pages/GestionarTalleres';
import ForgotPassword from './containers/pages/ForgotPassword';
function App() {
  return (

    <Provider store={store}>
      <Router>
        <Routes>
          {/* RUTAS */}
          <Route exact path='/' element={<Login />} />
          <Route exact path='/forgot_password' element={<ForgotPassword />} />
          <Route exact path='/singin' element={<Singin />} />
          <Route exact path='/request' element={<Request />} />
          <Route exact path='/request/:idPedido' element={<Request />} />
          <Route exact path='/fechas' element={<FechaHorario />} />
          <Route exact path='/fechas/:taller/:descp' element={<FechaHorario />} />
          <Route exact path='/fechas/:taller/:descp/:idPedido' element={<FechaHorario />} />
          <Route exact path='/landing' element={<Landing />} />
          <Route exact path='/api/pedidos/:id_usuario' element={<SolicitudList />} />
          <Route exact path='/landingadmin' element={<Landingadmin />} />
          <Route exact path='/component' element={<ComponentDev />} />

          <Route exact path='/carroproductos' element={<Carrito />} />
          <Route exact path='/AgregarProductos' element={<AgregarProductos />} />
          <Route exact path='/ListaProductos' element={<ListaProductos />} />
          <Route exact path='/CambioContrasena' element={<CambioContrasena />} />
          <Route exact path='/GestionarUsuarios' element={<GestionarUsuarios />} />
          <Route exact path='/GestionarTalleres' element={<GestionarTalleres />} />
        </Routes>
      </Router>
    </Provider>
  );
}


export default App;
