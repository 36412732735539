import React from 'react';
import Layout from "../../hocs/Layout";
import GestionarTaller from "../../components/GestionarTaller";
const GestionarTalleres = () => {
    return (
      <Layout>
        <main className="admin-background">
          <GestionarTaller />
        </main>
      </Layout>
    );
  }

  export default (GestionarTalleres);