import {
    GET_PEDIDOS_SUCCESS,
    GET_PEDIDOS_FAIL,
    PROCESAR_PEDIDO_SUCCESS,
    PROCESAR_PEDIDO_FAIL
  } from '../actions/types';
  
  const initialState = {
    pedidosUsuario: [],
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch(type) {
      case GET_PEDIDOS_SUCCESS:
        return {
          ...state,
          pedidosUsuario: Array.isArray(payload) ? payload : [] // Ensure payload is an array
        };
      case GET_PEDIDOS_FAIL:
        return {
          ...state,
          pedidosUsuario: [] // Fallback to an empty array on failure
        };
      case PROCESAR_PEDIDO_SUCCESS:
        return {
          ...state,
          pedidosUsuario: Array.isArray(state.pedidosUsuario) ? 
            state.pedidosUsuario.map(pedido =>
              pedido.id === action.payload.id ? { ...pedido, estado: action.payload.estado } : pedido
            ) : [] // Ensure pedidosUsuario is an array before calling map()
        };
      case PROCESAR_PEDIDO_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }
  }
  