import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./../css/NuevoProducto.css";
import { get_categorias } from '../redux/actions/categorias';  
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';  
import { v4 as uuidv4 } from 'uuid';
const NuevoProducto = ({ onClose, onProductAdded, categorias, get_categorias }) => {
    const [producto, setProducto] = useState({
        seccion: '',
        equipo: '',
        marca: '',
        descripcion: '',
        cantidad: '',
        estado: '',
        fecha_vencimiento: '',
        proveedor: '',
        categoria: '',
        observacion: '',
        reservado: false
    });
    const [images, setImages] = useState([]);
    const [previewUrls, setPreviewUrls] = useState([]);
    const [currentImageProgress, setCurrentImageProgress] = useState(0);
    const [isProcessingImage, setIsProcessingImage] = useState(false);
    const [editedProduct, setEditedProduct] = useState({ ...producto, newImages: [], removedImages: [] });
    const [selectedCategory, setSelectedCategory] = useState(
       
    );
    const [sections, setSections] = useState([]);
    const [selectedSection, setSelectedSection] = useState(
        
    );
    useEffect(() => {
        get_categorias();  
    }, [get_categorias]);
    useEffect(() => {
        if (selectedCategory) {
            const category = categorias.find(cat => cat.nombre === selectedCategory.value);
            if (category) {
                const sectionOptions = category.secciones.map(sec => ({
                    value: sec.nombre,
                    label: sec.nombre
                }));
                setSections(sectionOptions);

                // Set the initial section value
                const initialSection = sectionOptions.find(sec => sec.value === editedProduct.seccion);
                setSelectedSection(initialSection || null);
            }
        } else {
            setSections([]);
            setSelectedSection(null);
        }
    }, [selectedCategory, categorias, editedProduct.seccion]);
    const handleCategoryChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'create-option') {
            setSelectedCategory(newValue);
            setProducto(prev => ({
                ...prev,
                categoria: newValue.value
            }));
        } else {
            setSelectedCategory(newValue);
            setProducto(prev => ({
                ...prev,
                categoria: newValue ? newValue.value : null
            }));
        }
        // Clear section when category changes
        setSelectedSection(null);
        setProducto(prev => ({ ...prev, seccion: null }));
        setSections([]);
    };


    const handleSectionChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'create-option') {
            setSections(prevSections => [
                ...prevSections,
                { value: newValue.value, label: newValue.value }
            ]);
            setSelectedSection(newValue);
            setProducto(prev => ({
                ...prev,
                seccion: newValue.value
            }));
        } else {
            setSelectedSection(newValue);
            setProducto(prev => ({
                ...prev,
                seccion: newValue ? newValue.value : null
            }));
        }
    };
    const customStyles = {
        control: (provided, state) => ({
          ...provided,
          minWidth: '340px',
          maxWidth: '340px',
          boxShadow: state.isFocused ? 'none' : provided.boxShadow,
          borderColor: state.isFocused ? '#ccc' : provided.borderColor,
          borderWidth: '1px',
          '&:hover': {
            borderColor: state.isFocused ? '#ccc' : provided.borderColor
          },
        }),
        input: (provided) => ({
            ...provided,
            border: 'none !important',
            boxShadow: 'none !important',
            outline: 'none !important',
            '& input': {
              border: 'none !important',
              boxShadow: 'none !important',
              outline: 'none !important',
            },
          }),
        placeholder: (provided) => ({
          ...provided,
          color: '#999'
        }),
        menu: (provided) => ({
          ...provided,
          minWidth: '340px'
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? '#ccc' : provided.backgroundColor,
          '&:hover': {
            backgroundColor: '#f1f3f5'
          },
        }),
      };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProducto(prev => ({ ...prev, [name]: value }));
    };
    const compressImage = (file, maxSizeMB, maxWidth, maxHeight) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    let width = img.width;
                    let height = img.height;
    
                    // Resize logic
                    if (width > height) {
                        if (width > maxWidth) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width *= maxHeight / height;
                            height = maxHeight;
                        }
                    }
    
                    const canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
    
                    const compressAndCheck = (quality) => {
                        setCurrentImageProgress(Math.min(100, 100 - (quality * 100)));
                        canvas.toBlob(
                            (blob) => {
                                if (blob.size > maxSizeMB * 1024 * 1024 && quality > 0.1) {
                                    // If file is still too big, compress further
                                    compressAndCheck(quality - 0.1);
                                } else {
                                    const compressedFile = new File([blob], file.name, {
                                        type: 'image/webp',
                                        lastModified: Date.now(),
                                    });
                                    resolve(compressedFile);
                                }
                            },
                            'image/webp',
                            quality
                        );
                    };
    
                    // Start with quality 0.7 and reduce if necessary
                    compressAndCheck(0.7);
                };
                img.onerror = reject;
            };
            reader.onerror = reject;
        });
    };
    const handleImageChange = async (e) => {
        const fileInput = e.target;
        const file = fileInput.files[0];
        if (!file) return;
    
        setIsProcessingImage(true);
        setCurrentImageProgress(0);
    
        try {
            const maxSizeMB = 0.5;
            const maxWidth = 500;
            const maxHeight = 667;
    
            const compressedFile = await compressImage(file, maxSizeMB, maxWidth, maxHeight);
            setCurrentImageProgress(100);
    
            const webpFilename = file.name.replace(/\.[^/.]+$/, "") + ".webp";
            const renamedWebp = new File([compressedFile], webpFilename, { type: 'image/webp' });
    
            const previewUrl = URL.createObjectURL(renamedWebp);
    
            const newImage = { id: uuidv4(), file: renamedWebp, previewUrl };
    
            setImages(prev => [...prev, newImage]);
            setPreviewUrls(prev => [...prev, newImage.previewUrl]);
        } catch (error) {
            console.error('Error compressing image:', error);
            setIsProcessingImage(false);
        } finally {
            setIsProcessingImage(false); // Stop processing state after compression
            fileInput.value = ''; // Reset input value to allow uploading the same file again
        }
    };
    
    
    
    const handleRemoveImage = (idToRemove) => {
        setImages(prev => prev.filter((image) => image.id !== idToRemove));
        setPreviewUrls(prev => prev.filter((url, index) => images[index].id !== idToRemove));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        
        // Append other product fields
        Object.keys(producto).forEach(key => {
            if (producto[key] !== null && producto[key] !== undefined) {
                formData.append(key, producto[key]);
            }
        });
    
        // Append images (ensure correct field name)
        images.forEach((imageObj) => {
            formData.append('images', imageObj.file);
        });
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/crea-productos/`, formData, {
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `JWT ${localStorage.getItem('access')}`,
                }
            });
            onProductAdded(response.data);
            onClose();
        } catch (error) {
            console.error('Error adding product:', error);
        }
    };
    

    useEffect(() => {
        return () => {
            previewUrls.forEach(URL.revokeObjectURL);
        };
    }, [previewUrls]);

    return (
        <div className="nuevo-producto-modal">
            <div className="nuevo-producto-header">
                <h2>Nuevo Producto</h2>
                <button className="nuevo-producto-close" onClick={onClose}>&times;</button>
            </div>
            <form className="nuevo-producto-form" onSubmit={handleSubmit}>
                <div className="nuevo-producto-form-group">
                    <label htmlFor="categoria">Categoría:</label>
                    <CreatableSelect
    id="categoria"
    isClearable
    placeholder="Crear categoría o seleccionar"
    value={selectedCategory}
    onChange={handleCategoryChange}
    options={categorias.map(cat => ({
        value: cat.nombre,
        label: cat.nombre
    }))}
    formatCreateLabel={(inputValue) => `Crear categoría: ${inputValue}`}
    styles={customStyles}
/>
                </div>
                <div className="nuevo-producto-form-group">
                    <label htmlFor="seccion">Sección:</label>
                    <CreatableSelect
    id="seccion"
    isClearable
    placeholder="Crear sección o seleccionar"
    value={selectedSection}
    onChange={handleSectionChange}
    options={sections}
    formatCreateLabel={(inputValue) => `Crear sección: ${inputValue}`}
    styles={customStyles}
/>
                </div>
                {[
                   
                    { id: 'equipo', name: 'Equipo', value: producto.equipo, type: 'text', placeholder: 'Equipo' },
                    { id: 'marca', name: 'Marca', value: producto.marca, type: 'text', placeholder: 'Marca' },
                    { id: 'descripcion', name: 'Descripción', value: producto.descripcion, type: 'textarea', placeholder: 'Descripción' },
                    { id: 'cantidad', name: 'Cantidad', value: producto.cantidad, type: 'number', placeholder: 'Cantidad' },
                    { id: 'estado', name: 'Estado', value: producto.estado, type: 'text', placeholder: 'Estado' },
                    { id: 'fecha_vencimiento', name: 'Fecha de Vencimiento', value: producto.fecha_vencimiento, type: 'date' },
                    { id: 'proveedor', name: 'Proveedor', value: producto.proveedor, type: 'text', placeholder: 'Proveedor' },
                    { id: 'observacion', name: 'Observación', value: producto.observacion, type: 'textarea', placeholder: 'Observación' }
                ].map((input, index) => (
                    <div className="nuevo-producto-form-group" key={index}>
                        <label htmlFor={input.id}>{input.name}:</label>
                        {input.type === 'textarea' ? (
                            <textarea
                                id={input.id}
                                name={input.id}
                                value={input.value}
                                onChange={handleChange}
                                placeholder={input.placeholder}
                                required={input.required}
                            />
                        ) : (
                            <input
                                id={input.id}
                                name={input.id}
                                type={input.type}
                                value={input.value}
                                onChange={handleChange}
                                placeholder={input.placeholder}
                                required={input.required}
                            />
                        )}
                    </div>
                ))}
    
                <div className="nuevo-producto-form-group">
                    <label>Imágenes:</label>
                    <div className="image-upload-container">
                        {images.map((image, index) => (
                            <div key={image.id} className="image-preview-item">
                                <img src={image.previewUrl} alt={`Preview ${index}`} />
                                <button type="button" className="remove-image-btn" onClick={() => handleRemoveImage(image.id)}>✕</button>
                            </div>
                        ))}
                        <label htmlFor="image-upload" className="image-upload-label">
                            <div className="plus-icon">+</div>
                        </label>
                    </div>
                    <input 
                        type="file" 
                        id="image-upload" 
                        name="images" 
                        onChange={handleImageChange} 
                        accept="image/*" 
                        multiple 
                        disabled={isProcessingImage}
                    />
                    {isProcessingImage && (
                        <div className="image-processing-progress">
                            <progress value={currentImageProgress} max="100"></progress>
                            <span>{currentImageProgress.toFixed(0)}%</span>
                        </div>
                    )}
                </div>
    
                <button className="submitButton" type="submit">Guardar Producto</button>
            </form>
        </div>
    );
    
    
};

const mapStateToProps = (state) => ({
    categorias: state.Categorias.categorias,
});

export default connect(mapStateToProps, { get_categorias })(NuevoProducto);