import React, { useEffect, useRef } from 'react';
import '../css/GestionarTallerModal.css';

const GestionarTallerModal = ({ isOpen, onClose, onSubmit, formValues, handleInputChange, isEditMode }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="GT-modal-overlay">
      <div className="GT-modal-content" ref={modalRef}>
        <button className="GT-modal-close" onClick={onClose}>X</button>
        <form onSubmit={onSubmit} className="GT-form">
          <h2 className="GT-form-title">{isEditMode ? 'Editar Taller' : 'Crear Taller'}</h2>
          <div className="GT-form-group">
            <input
              type="text"
              name="nombre"
              className="GT-form-input"
              placeholder="Nombre"
              value={formValues.nombre}
              onChange={handleInputChange}
            />
          </div>
          <div className="GT-form-group">
            <input
              type="text"
              name="descripcion"
              className="GT-form-input"
              placeholder="Descripción"
              value={formValues.descripcion}
              onChange={handleInputChange}
            />
          </div>
          <div className="GT-form-group">
            <label className="GT-form-label">
              Fecha de Inicio:
              <input
                type="date"
                name="fechaInicio"
                className="GT-form-input"
                value={formValues.fechaInicio}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div className="GT-form-group">
            <label className="GT-form-label">
              Fecha de termino:
              <input
                type="date"
                name="fechaTermino"
                className="GT-form-input"
                value={formValues.fechaTermino}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div className="GT-form-submit-container">
            <button type="submit" className="GT-form-submit">
              {isEditMode ? 'Editar Taller' : 'Crear Taller'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GestionarTallerModal;
