import React from 'react';
import Layout from "../../hocs/Layout";
import Contrasena from "../../components/Contrasena";
const CambioContrasena = () => {
    return (
      <Layout>
          <Contrasena />
      </Layout>
    );
  }

  export default (CambioContrasena);