import axios from 'axios';
import {
    GET_TALLERES_SUCCESS,
    GET_TALLERES_FAIL,
    CREATE_TALLER_SUCCESS,
    CREATE_TALLER_FAIL,
    UPDATE_TALLER_SUCCESS,
    UPDATE_TALLER_FAIL,
    DELETE_TALLER_SUCCESS,
    DELETE_TALLER_FAIL,
} from './types';

// List tallers
export const listTalleres = () => async dispatch => {
    try {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
            }
        };
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/list-talleres/`, config);
        dispatch({
            type: GET_TALLERES_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: GET_TALLERES_FAIL
        });
    }
};

// Create taller
export const createTaller = (nombre, descripcion, fechaInicio, fechaTermino) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    const body = JSON.stringify({ nombre, descripcion, fechaInicio, fechaTermino });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/create-taller/`, body, config);
        dispatch({
            type: CREATE_TALLER_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        dispatch({ type: CREATE_TALLER_FAIL });
    }
};
// Update taller
export const updateTaller = (id, nombre, descripcion, fechaInicio, fechaTermino) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    const body = JSON.stringify({ id, nombre, descripcion, fechaInicio, fechaTermino });

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/update-taller/`, body, config);
        dispatch({
            type: UPDATE_TALLER_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        dispatch({ type: UPDATE_TALLER_FAIL });
    }
};

// Delete taller
export const deleteTaller = id => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`
        }
    };

    try {
        // Ensure the token is in the headers and the data is correctly formatted
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/delete-taller/`, {
            headers: config.headers,
            data: { id }
        });
        dispatch({
            type: DELETE_TALLER_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        dispatch({ type: DELETE_TALLER_FAIL });
    }
};
