import React, { useState, useEffect, useRef } from 'react';
import '../css/Sidebaradmin.css';
import Imagenes from '../images/logoturismo.png';
import SolicitudLogo from '../images/crearsolicitud.png';
import Logossoli from '../images/21.png';
import cambiologo from '../images/32.png';
import Logofflogo from '../images/cierresesion.png';
import { logout } from '../redux/actions/auth';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Ruedita from '../images/configuracion.png';
import confusuario from '../images/confuser.png';

const Sidebaradmin = ({ logout }) => {
    const [showSidebar, setShowSidebar] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const categoryRef = useRef(null);

    const logoutHandler = () => {
        logout();
        setRedirect(true);
    };

    if (redirect) {
        window.location.reload(false);
        window.location.href = '/';
    }

    const handleClickOutside = (event) => {
        if (categoryRef.current && !categoryRef.current.contains(event.target)) {
            setShowSidebar(false); 
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleSidebarClick = () => {
        setShowSidebar(!showSidebar);
    };

    return (
        <>
            {showSidebar ? (
                <div className='sidebar-bg' onClick={handleSidebarClick}>
                    <div className='sidebar' ref={categoryRef}>
                        <img className='img-ecoturismo' src={Imagenes} alt="DUOC ECOTURISMO" />
                        <hr />
                        <div className="sidebar-columnas">
                            <ul className='sidebar_list'>

                                 <Link className='sidebar_item_list' to='/landing'>
                                <div className='sidebar_item_link_link' >
                                        <img className='sidebar_icon' src={Logossoli} alt="" />
                                        <p className='sidebar_item_text'>Resolver solicitudes</p> 
                                        </div>
                                </Link>
                                <Link className='sidebar_item_list' to='../ListaProductos'>
                                <div className='sidebar_item_link_link' >
                                        <img className='sidebar_icon' src={SolicitudLogo} alt="" />
                                        <p className='sidebar_item_text'>Modificar inventario</p> 
                                        </div>
                                </Link>
                                <Link className='sidebar_item_list' to='/GestionarUsuarios'>
                                <div className='sidebar_item_link_link' >
                                        <img className='sidebar_icon' src={confusuario} alt="" />
                                        <p className='sidebar_item_text'>Gestionar cuentas de usuario</p>
                                        </div>
                                </Link>
                                <Link className='sidebar_item_list' to='/GestionarTalleres'>
                                <div className='sidebar_item_link_link' >
                                        <img className='sidebar_icon' src={confusuario} alt="" />
                                        <p className='sidebar_item_text'>Gestionar talleres</p>
                                        </div>
                                </Link>
                                <Link className='sidebar_item_list' to='/CambioContrasena'>
                                <div className='sidebar_item_link_link' >
                                        <img className='sidebar_icon' src={cambiologo} alt="" />
                                        <p className='sidebar_item_text'>Cambio de contraseña</p>
                                        </div>
                                </Link>
                                <Link className='sidebar_item_list' to='/' onClick={logoutHandler}>
                                <div className='sidebar_item_link_link' >
                                        <img className='sidebar_icon' src={Logofflogo} alt="" />
                                        <p className='sidebar_item_text'>Cerrar sesión</p>
                                        </div>
                                </Link>
                            </ul>

                        </div>
                    </div>
                </div>
            ) : (
                <button onClick={handleSidebarClick} className="sidebar-btn">
                    ❯
                </button>
            )}
        </>
    );
};

export default connect(null, {
    logout
})(Sidebaradmin);
