import Layout from "../../hocs/Layout";
import '../../css/Carrito.css'
import Productscarrito from "../../components/Productscarrito";

const Carro = () => {
    return (
      <Layout>
          <Productscarrito />
      </Layout>
    );
  }

  export default (Carro);