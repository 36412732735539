import React from 'react';
import Layout from "../../hocs/Layout";
import AdminProductList from '../../components/AdminProductList.jsx';
import "./../../css/AdminPage.css";

const AdminPage = () => {
    return (
        <Layout>
            <main className="admin-background">
                <AdminProductList />
            </main>
        </Layout>
    );
};

export default AdminPage;