import { useEffect, useRef, useState } from "react";
import { connect } from 'react-redux';
import { get_categorias } from "../redux/actions/categorias";
import '../css/CategoryList.css';
import { LuPlus, LuMinus } from "react-icons/lu";

const CategoryList = ({ get_categorias, categorias, mostrarPorCategoria, mostrarPorEquipo }) => {
    useEffect(() => {
        get_categorias();
    }, [get_categorias]);

    const categoryRef = useRef(null);
    const [showSecciones, setShowSecciones] = useState(false);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [selectedSeccion, setSelectedSeccion] = useState(null);

    const handleClickOutside = (event) => {
        if (categoryRef.current && !categoryRef.current.contains(event.target)) {
            setShowSecciones(false);
            setCurrentCategory(null);
            setSelectedSeccion(null);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleCategoryClick = (categoria) => {
        if (categoria === "Todo") {
            setCurrentCategory(null);
            setSelectedSeccion(null);
            setShowSecciones(false);
            mostrarPorCategoria("Todo"); 
        } else {
            if (currentCategory && currentCategory.id === categoria.id) {
                setShowSecciones(!showSecciones);
            } else {
                setCurrentCategory(categoria);
                setShowSecciones(true);
            }
            setSelectedSeccion(null);
            mostrarPorCategoria(categoria.nombre);
        }
    };
    

    const handleSeccionClick = (seccion, event) => {
        event.stopPropagation();
        setSelectedSeccion(seccion);
        mostrarPorCategoria(currentCategory.nombre, seccion.nombre);
    };

    return (
        <div ref={categoryRef} className="categorias">
            <div className="categorias__table">
                <table>
                    <thead className="categorias__thead">
                        <tr>
                            <th className="categorias__title">Categorías</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="categorias_list" onClick={() => handleCategoryClick("Todo")}>
                            <td>{"Todo"} {(!currentCategory && !showSecciones) ? <LuMinus /> : <LuPlus />}</td>
                        </tr>
                        {categorias && categorias.map(categoria => (
                            <tr className="categorias_list" key={categoria.id} onClick={() => handleCategoryClick(categoria)}>
                                <td>{categoria.nombre} {currentCategory && currentCategory.id === categoria.id  ? <LuMinus /> : <LuPlus />}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showSecciones && currentCategory && currentCategory.secciones && (
    <div className="sub_categorias__table">
    <div className="sub_categorias__header">
        <h2
            className={`sub_categorias__title ${!selectedSeccion ? 'selected' : ''}`}
            onClick={() => {
                setSelectedSeccion(null);
                mostrarPorCategoria(currentCategory.nombre);
            }}
        >
            {currentCategory.nombre}
        </h2>
        <button
            className="close-button"
            onClick={() => setShowSecciones(false)}
        >
            &times;
        </button>
    </div>
    
    <ul className="sub_categorias_list">
        {currentCategory.secciones && currentCategory.secciones.map(seccion => (
            <li
                key={seccion.id}
                className={`sub_categoria_list_item ${selectedSeccion && selectedSeccion.id === seccion.id ? 'selected' : ''}`}
                onClick={(event) => handleSeccionClick(seccion, event)}
            >
                {seccion.nombre}
            </li>
        ))}
    </ul>
</div>
)}
        </div>
    );
};

const mapStateToProps = state => ({
    categorias: state.Categorias.categorias,
});

export default connect(mapStateToProps, {
    get_categorias,
})(CategoryList);