import React, { useState, useEffect, useRef } from 'react';
import '../css/Sidebar.css';
import Imagenes from '../images/logoturismo.png';
import SolicitudLogo from '../images/crearsolicitud.png';
import Logossoli from '../images/21.png';
import cambiologo from '../images/32.png';
import Logofflogo from '../images/cierresesion.png';
import { logout } from '../redux/actions/auth';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";


const Sidebar = ({ logout }) => {

    const [showSidebar, setShowSidebar] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const logoutHandler = () => {
        logout()
        setRedirect(true);
    }
    if (redirect) {
        window.location.reload(false);
        window.location.href = '/';
    }
    const categoryRef = useRef(null);

    const handleClickOutside = (event) => {
        if (categoryRef.current && !categoryRef.current.contains(event.target)) {
            setShowSidebar(true);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleSidebarClick = () => {
        setShowSidebar(!showSidebar); // Invierte el valor actual de showSidebar
    };

    return (

        <>
            {showSidebar ? (
                <div className='sidebar-bg' onClick={handleSidebarClick}>
                    <div className='sidebar' ref={categoryRef}>
                        <img className='sidebar-img' src={Imagenes} alt="DUOC ECOTURISMO" />
                        <hr />
                        <div className="sidebar-columnas">
                            <ul className='sidebar_list'>
                                <Link className='sidebar_item_list' to='/request'>
                                <div className='sidebar_item_link_link' >
                                        <img className='sidebar_icon' src={SolicitudLogo} alt="" />
                                        <p className='sidebar_item_text'>Crear Solicitud</p> 
                                        </div>
                                </Link>
                                <Link className='sidebar_item_list' to='/landing'>
                                <div className='sidebar_item_link_link' >
                                        <img className='sidebar_icon' src={Logossoli} alt="" />
                                        <p className='sidebar_item_text'>Solicitudes</p> 
                                        </div>
                                </Link>
                                <Link className='sidebar_item_list' to='/CambioContrasena'>
                                <div className='sidebar_item_link_link' >
                                        <img className='sidebar_icon' src={cambiologo} alt="" />
                                        <p className='sidebar_item_text'>Cambio de contraseña</p>
                                        </div>
                                </Link>
                                <Link className='sidebar_item_list' to='/' onClick={logoutHandler}>
                                <div className='sidebar_item_link_link' >
                                        <img className='sidebar_icon' src={Logofflogo} alt="" />
                                        <p className='sidebar_item_text'>Cerrar sesión</p>
                                        </div>
                                </Link>
                            </ul>

                        </div>
                    </div>
                </div>
            ):(<button onClick={handleSidebarClick} className="sidebar-btn">
            ❯
        </button>)}
        </>
    );
};

export default connect(null, {
    logout
})(Sidebar);
