import { FaTrash, FaTimes } from 'react-icons/fa';
import { connect } from "react-redux";
import { addItemSolicitud, deleteItemSolicitud, deleteItemSolicitudCompletamente  } from "../redux/actions/solicitud";
import { useState, useEffect } from "react";
import noImageIcon from '../images/no-image-icon.svg';


const ProductoCarrito = ({producto, itemsCarrito, addItemSolicitud, deleteItemSolicitud, deleteItemSolicitudCompletamente }) =>{

    const [cantidad, setCantidad] = useState(0);
    useEffect(() => {
        if (itemsCarrito) {
          const itemInCarrito = itemsCarrito.find(item => item.producto.id === producto.id);
          if (itemInCarrito) {
          } else {
          }
        }
      }, [itemsCarrito, producto.id]);

    const incrementarCantidad = () => {

        if (cantidad < producto.cantidad) {
          addItemSolicitud({
            id: producto.id,
            equipo: producto.producto.equipo,
            seccion: producto.producto.seccion,
            descripcion: producto.producto.descripcion,
            cantidad: producto.producto.cantidad,
            estado: producto.producto.estado,
            fecha_vencimiento: producto.producto.fecha_vencimiento,
            proveedor: producto.producto.proveedor,
            categoria: producto.producto.categoria,
            observacion: producto.producto.observacion,
            reservado: producto.producto.reservado
          },producto.producto);
        } else {
          console.log(`No se puede agregar más del producto ${producto.id}. Cantidad máxima alcanzada.`);
        }
      };

      const eliminarProducto = () => {
        deleteItemSolicitudCompletamente(producto);
        setCantidad(0);
      };
      const getImageUrl = (imagePath) => {
        if (!imagePath) return noImageIcon;
        return `${process.env.REACT_APP_API_URL}${imagePath}`;
      };
      const imageUrl = producto.image ? getImageUrl(producto.image.image) : noImageIcon;
    return(
        <div className='d-flex flex-column'>
                  <p>{producto.producto.equipo}</p>
                  <div className='d-flex justify-content-center'>
                    <img src={imageUrl} alt={producto.producto.equipo} />
                    <footer>
                      <div className="item-counter">
                        <button 
                          className="delete-button botones-carrito"
                          onClick={() => deleteItemSolicitud(producto,producto.producto,0)}
                        >
                          <FaTrash />
                        </button>
                        <button 
                          className="minus-button botones-carrito"
                          onClick={() => deleteItemSolicitud(producto,producto.producto)}
                        >
                          -
                        </button>
                        <span className="item-count">{producto.cantidad} un.</span>
                        <button 
                          className="plus-button botones-carrito"
                          onClick={() => incrementarCantidad(producto,producto.producto)}
                        >
                          +
                        </button>
                      </div>
                    </footer>
                  </div>
                </div>
    );
}

const mapStateToProps = (state) => ({
    carrito: state.Solicitud.carrito,
  });
export default connect(mapStateToProps, {
    addItemSolicitud,
    deleteItemSolicitud,
    deleteItemSolicitudCompletamente
  })(ProductoCarrito);
  