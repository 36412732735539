import Layout from "../../hocs/Layout";
import SolicitudForm from "../../components/SolicitudForm";
import '../../css/Request.css'
import { useParams } from "react-router-dom";
const Request = () => {
    const { idPedido } = useParams();
    return (
        <Layout>
            <div className="request">
                <SolicitudForm idPedido={idPedido}/>
            </div>
        </Layout>
    );
}

export default Request;