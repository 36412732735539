import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { forgotPassword } from '../redux/actions/auth'
import { connect } from 'react-redux'

const ForgotPasswordForm = ({ forgotPassword, error }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [email, setEmail] = useState('')

  const [submitError, setSubmitError] = useState('')
  const [submitSuccess, setSubmitSuccess] = useState('')
  const [loading, setLoading] = useState(false)

  const onChange = (e) => setEmail(e.target.value)

  const onSubmit = async (e) => {
    e.preventDefault()
    if (!email) {
      setSubmitError('Por favor, ingresa tu email.')
      return
    }
    try {
      setLoading(true)
      const result = await forgotPassword(email)
      if (result.success) {
        window.scrollTo(0, 0)
        setSubmitSuccess('Correo enviado.')
        setSubmitError('')
        setLoading(false)
      } else {
        setSubmitError('Correo no encontrado. Por favor, inténtalo de nuevo.')
        setSubmitSuccess('')
        setLoading(false)
      }
    } catch (err) {
      setSubmitError('Error al restablecer contraseña. Por favor, inténtalo de nuevo.')
    }
  }

  return (
    <form onSubmit={onSubmit} className='formulario justify-content-center text-center'>
      <h2 className='formulario-h2'>Contraseña olvidada</h2>
      {(submitError || error) && <p style={{ color: 'red' }}>{submitError || error}</p>}
      {(submitSuccess || error) && <p style={{ color: 'green' }}>{submitSuccess || error}</p>}
      <div className='form-group'>
        <input
          id='email'
          name='email'
          type='email'
          className='formulario-input my-2'
          placeholder='Email'
          aria-describedby='emailHelp'
          value={email}
          onChange={onChange}
        />
      </div>
      <div className='my-4 d-flex justify-content-center'>
        <button
          style={{ fontFamily: 'Nunito' }}
          type='submit'
          className='botoncito btn-iniciar-sesion'
          disabled={loading}
        >
          Enviar
        </button>
      </div>
      <Link to='/' style={{ fontFamily: 'Nunito' }} className='botoncito volver'>
        Volver
      </Link>
    </form>
  )
}

const mapStateToProps = (state) => ({
  loading: state.Auth.loading,
  error: state.Auth.error,
})

export default connect(mapStateToProps, { forgotPassword })(ForgotPasswordForm)
