import {
    GET_PEDIDOS_SUCCESS,
    GET_PEDIDOS_FAIL
} from '../actions/types';
const intitialState = {
    pedidosUsuario: null,
};
export default function Pedidos(state = intitialState, action) {
    const { type, payload } = action;
    switch (type) {

        case GET_PEDIDOS_SUCCESS:
            console.log(payload)
            return {
                ...state,
                pedidosUsuario: payload.pedidosUsuario,
                totalPaginas: payload.totalPaginas
            }
        case GET_PEDIDOS_FAIL:
            return {
                ...state,
                pedidosUsuario: [],
                totalPaginas: 0
            }
        default:
            return state

    }
}