import axios from 'axios';
import {
    GET_USERS_SUCCESS,
    GET_USERS_FAIL,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAIL,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    SET_AUTH_LOADING,
    REMOVE_AUTH_LOADING,
} from './types';

// List users
export const listUsers = () => async dispatch => {
    try {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
            }
        };
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/listar-profesores/`, config);
        dispatch({
            type: GET_USERS_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: GET_USERS_FAIL
        });
    }
};

// Create user
export const createUser = (nombre, email, password, isStaff, estado) => async dispatch => {
    dispatch({ type: SET_AUTH_LOADING });
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    const body = JSON.stringify({ nombre, email, password, isStaff, estado });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/crear-profesor/`, body, config);
        dispatch({
            type: CREATE_USER_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        dispatch({ type: CREATE_USER_FAIL });
    } finally {
        dispatch({ type: REMOVE_AUTH_LOADING });
    }
};
// Update user
export const updateUser = (id, nombre, email, isStaff, estado,password) => async dispatch => {
    dispatch({ type: SET_AUTH_LOADING });
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    const body = JSON.stringify({ id, nombre, email, isStaff, estado,password });

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/actualizar-profesor/`, body, config);
        dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        dispatch({ type: UPDATE_USER_FAIL });
    } finally {
        dispatch({ type: REMOVE_AUTH_LOADING });
    }
};

// Delete user
// Delete user
export const deleteUser = id => async dispatch => {
    dispatch({ type: SET_AUTH_LOADING });
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`
        }
    };

    try {
        // Ensure the token is in the headers and the data is correctly formatted
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/eliminar-profesor/`, {
            headers: config.headers,
            data: { id }
        });
        dispatch({
            type: DELETE_USER_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        dispatch({ type: DELETE_USER_FAIL });
    } finally {
        dispatch({ type: REMOVE_AUTH_LOADING });
    }
};
