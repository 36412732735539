import '../css/Confirmar.css'
import { connect } from "react-redux";
import { createPedido } from '../redux/actions/solicitud';
import { Navigate, useNavigate } from 'react-router-dom';
const Confirmar = ({pedido, setShowConfirmar, createPedido}) =>{
    const navigate = useNavigate();

    const handleVolverClick = () => {
        setShowConfirmar(false);
    };

    const handleEnviarClick = () => {

        // Llamamos a createPedido con el pedido como argumento
        createPedido(pedido);
        setShowConfirmar(false);
        navigate('/landing');
    };
    return(
        <div className="bg-confirmar">
            <div className="confirmar">
                <div className="confirmar_options">
                    <h1>¿Estás seguro?</h1>
                    <div className="confirmar_buttons">
                        <button onClick={handleVolverClick} >Volver</button>
                        <button onClick={handleEnviarClick}>Enviar</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => ({

  });
  
  export default connect(mapStateToProps, {
    createPedido
  })(Confirmar);
  