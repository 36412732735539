import axios from 'axios';
import { ADD_ITEM_SOLICITUD, DELETE_ITEM_SOLICITUD, CREATE_PEDIDO_SUCCESS, CREATE_PEDIDO_FAIL, GET_SOLICITUD_SUCCESS, GET_SOLICITUD_FAIL, SET_FECHA_SUCCESS, SET_FECHA_FAIL, SET_HORARIOS_SUCCESS, SET_HORARIOS_FAIL, SET_TALLER_SUCCESS, SET_TALLER_FAIL, SET_DESCRIPCION_SUCCESS, SET_DESCRIPCION_FAIL, SET_USUARIO_SUCCESS, SET_USUARIO_FAIL, RESET_SOLICITUD } from './types';

export const getSolicitud = (pedido) => async dispatch => {
  if (localStorage.getItem('access')) {

    const config = {
      headers: {
        'Accept': 'application/json'
      }
    };
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/pedido/${pedido}`, config);
      if (res.status === 200) {
        dispatch({
          type: GET_SOLICITUD_SUCCESS,
          payload: res.data
        });
      } else {
        dispatch({
          type: GET_SOLICITUD_FAIL
        });
      }
    } catch (err) {
      dispatch({
        type: GET_SOLICITUD_FAIL
      });
    }
  } else {
    dispatch({
      type: GET_SOLICITUD_FAIL
    });

  }
}

export const addItemSolicitud = (producto, productoItem) => (dispatch, getState) => {

  const state = getState();
  let carrito = { ...state.Solicitud.carrito }; // Crear una copia para no mutar el estado directamente
  if (!carrito.items) {
    carrito.id = null;
    carrito.usuario = null;
    carrito.total_items = 0;
    carrito.items = []

  }
  // Verificar si el producto ya está en el carrito
  let existingItem = null;

  if (productoItem) {
    existingItem = carrito.items.find(item => item.producto.id === productoItem.id);
  } else {
    existingItem = carrito.items.find(item => item.producto.id === producto.id);
  }

  if (existingItem) {
    // Si el producto ya está en el carrito, verificar si se puede agregar más
    if (existingItem.cantidad < producto.cantidad) {
      existingItem.cantidad += 1;
    } else {
      console.log(`No se puede agregar más del producto ${producto.id}. Cantidad máxima alcanzada.`);
      return; // Salir de la función sin hacer nada
    }
  } else {
    // Si el producto no está en el carrito, agregarlo con cantidad 1 y la estructura adecuada

    const nuevoItem = {
      id: Date.now(), // Crear un id temporal para el nuevo item
      producto: {
        id: producto.id,
        equipo: producto.equipo,
        seccion: producto.seccion,
        descripcion: producto.descripcion,
        cantidad: producto.cantidad, // Esta es la cantidad disponible del producto y no debe cambiar
        estado: producto.estado,
        fecha_vencimiento: producto.fecha_vencimiento,
        proveedor: producto.proveedor,
        categoria: producto.categoria,
        observacion: producto.observacion,
        reservado: producto.reservado
      },
      image: producto.images[0],
      cantidad: 1, // La cantidad en el carrito debe empezar en 1
      carrito: carrito.id // Asumir que el carrito ya tiene un id asignado
    };
    carrito.items.push(nuevoItem);
  }

  carrito.total_items = carrito.items.length;

  dispatch({
    type: ADD_ITEM_SOLICITUD,
    payload: carrito,
  });
};

export const deleteItemSolicitud = (producto, productoItem, set) => (dispatch, getState) => {
  const state = getState();
  let carrito = { ...state.Solicitud.carrito }; // Crear una copia para no mutar el estado directamente

  let existingItem = null;

  if (productoItem) {
    existingItem = carrito.items.find(item => item.producto.id === productoItem.id);
  } else {
    existingItem = carrito.items.find(item => item.producto.id === producto);
  }
  if (existingItem) {
    if (existingItem.cantidad > 1) {
      existingItem.cantidad -= 1;
    } else {
      carrito.items = carrito.items.filter(item => item.producto.id !== existingItem.producto.id); // Remover el producto del carrito
    }
  } else {
    console.log(`El producto ${producto.id} no se encuentra en el carrito.`);
  }
  if (set != undefined) {
    existingItem.cantidad = 0
    if (existingItem.cantidad > 1) {
      existingItem.cantidad -= 1;
    } else {
      carrito.items = carrito.items.filter(item => item.producto.id !== existingItem.producto.id); // Remover el producto del carrito
    }

  }
  carrito.total_items = carrito.items.length;

  dispatch({
    type: DELETE_ITEM_SOLICITUD,
    payload: carrito,
  });
};
export const deleteItemSolicitudCompletamente = (producto) => (dispatch, getState) => {
  const state = getState();
  let carrito = { ...state.Solicitud.carrito };
  carrito.items = carrito.items.filter(item => item.producto.id !== producto);
  carrito.total_items = carrito.items.length;
  dispatch({
    type: DELETE_ITEM_SOLICITUD,
    payload: carrito,
  });
};


export const createPedido = (pedido) => async (dispatch) => {
  pedido.carrito.total_items = pedido.carrito.items.length
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `JWT ${localStorage.getItem('access')}`,
    }
  };

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/crear-pedido-final/`, pedido, config);
    dispatch({
      type: CREATE_PEDIDO_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: CREATE_PEDIDO_FAIL
    });
    throw err;
  }
};

export const setFecha = (fecha) => async (dispatch) => {

  try {
    dispatch({
      type: SET_FECHA_SUCCESS,
      payload: { fecha_creacion: fecha.startDate, fecha_resolucion: fecha.endDate }
    });
  } catch (err) {
    dispatch({
      type: SET_FECHA_FAIL
    });
  }
}

export const setHorarios = (horarios) => async (dispatch) => {
  try {
    dispatch({
      type: SET_HORARIOS_SUCCESS,
      payload: { horarios_selected: horarios }
    });
  } catch (err) {
    dispatch({
      type: SET_HORARIOS_FAIL
    });
  }

}

export const updateTaller = (tallerNuevo) => async (dispatch) => {
  try {
    dispatch({
      type: SET_TALLER_SUCCESS,
      payload: { taller: tallerNuevo }
    });
  } catch (err) {
    dispatch({
      type: SET_TALLER_FAIL
    });
  }
}

export const updateDescripcion = (descripcionNuevo) => async (dispatch) => {
  try {
    dispatch({
      type: SET_DESCRIPCION_SUCCESS,
      payload: { descripcion: descripcionNuevo }
    });
  } catch (err) {
    dispatch({
      type: SET_DESCRIPCION_FAIL
    });
  }
}
export const updateUsuario = (usuarioNuevo) => async (dispatch) => {
  try {
    dispatch({
      type: SET_USUARIO_SUCCESS,
      payload: { usuario: usuarioNuevo }
    });
  } catch (err) {
    dispatch({
      type: SET_USUARIO_FAIL
    });
  }

}
export const resetSolicitud = () => ({
  type: RESET_SOLICITUD,
});