import React, { useEffect, useState } from 'react';
import '../css/Horarios.css';
import { connect } from 'react-redux';
import { setHorarios } from '../redux/actions/solicitud';

const Horarios = ({ horariosSelected, setHorarios, onChange }) => {
  const options = [
    { value: '1', label: '8.31 - 9.50', start: 8, end: 9, minuteStart: 31, minuteEnd: 50 },
    { value: '2', label: '10.01 - 11.20', start: 10, end: 11, minuteStart: 1, minuteEnd: 20 },
    { value: '3', label: '11.31 - 12.50', start: 11, end: 12, minuteStart: 31, minuteEnd: 50 },
    { value: '4', label: '13.01 - 14.20', start: 13, end: 14, minuteStart: 1, minuteEnd: 20 },
    { value: '5', label: '14.31 - 15.50', start: 14, end: 15, minuteStart: 31, minuteEnd: 50 },
    { value: '6', label: '16.01 - 17.20', start: 16, end: 17, minuteStart: 1, minuteEnd: 20 },
    { value: '7', label: '17.31 - 18.50', start: 17, end: 18, minuteStart: 31, minuteEnd: 50 },
    { value: '8', label: '19.01 - 20.20', start: 19, end: 20, minuteStart: 1, minuteEnd: 20 },
    { value: '9', label: '20.31 - 21.50', start: 20, end: 21, minuteStart: 31, minuteEnd: 50 },
    { value: '10', label: '22.51 - 23.30', start: 22, end: 23, minuteStart: 51, minuteEnd: 30 },
  ];

  const [selected, setSelected] = useState([]);
  const [selectionStart, setSelectionStart] = useState(null);

  useEffect(() => {
    const savedHorarios = JSON.parse(sessionStorage.getItem('horarios_selected')) || [];
    if (savedHorarios.length > 0) {
      setSelected(savedHorarios);
    } else if (horariosSelected && Array.isArray(horariosSelected)) {
      setSelected(horariosSelected.map(horario => horario.toString()));
    }
    // Initialize selected horarios based on Redux state
    if (horariosSelected && Array.isArray(horariosSelected)) {
      setSelected(horariosSelected.map(horario => horario.toString()));
    }
  }, []);

  const handleButtonClick = (clickedValue) => {
    const clickedValueNumber = Number(clickedValue);

    if (selectionStart === null) {
      // First click: set start
      setSelectionStart(clickedValueNumber);
      setSelected([clickedValue]);
    } else {
      // Second click: set end and update selection
      const start = Math.min(selectionStart, clickedValueNumber);
      const end = Math.max(selectionStart, clickedValueNumber);

      const newSelection = options
        .filter(opt => {
          const val = Number(opt.value);
          return val >= start && val <= end;
        })
        .map(opt => opt.value);

      setSelected(newSelection);
      setSelectionStart(null); // Reset for next selection
    }

    // Notify parent of the change
    onChange(selected);
  };

  useEffect(() => {
    // Dispatch setHorarios only when the selected horarios change
    if (JSON.stringify(selected) !== JSON.stringify(horariosSelected)) {
      setHorarios(selected);
      sessionStorage.setItem('horarios_selected', JSON.stringify(selected));
    }
  }, [selected, horariosSelected, setHorarios, onChange]);
  

  return (
    <div className="horario-selector">
      <h2 className='titulo'>Horarios</h2>
      <div className="options-container">
        {options.map((option) => {
          const isSelected = selected.includes(option.value);
          return (
            <div
              key={option.value}
              className={`option ${isSelected ? 'selected' : ''} ${Number(option.value) === selectionStart ? 'start' : ''}`}
              onClick={() => handleButtonClick(option.value)}
            >
              {option.label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  horariosSelected: state.Solicitud.horarios_selected
});

export default connect(mapStateToProps, {
  setHorarios
})(Horarios);
