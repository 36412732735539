import React, { useState, useEffect } from 'react';
import '../css/Veritems.css';
import { FaTimes } from 'react-icons/fa';
import axios from 'axios';
import noImageIcon from '../images/no-image-icon.svg';

const Showitems = ({ onClose, pedido, handleShowCheckList }) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-producto-id-pedido/${pedido.id}`);
                setItems(response.data);
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        };

        fetchItems();
    }, [pedido]);

    const getImageUrl = (imagePath) => {
        if (!imagePath) return noImageIcon;
        return `${process.env.REACT_APP_API_URL}${imagePath}`;
    };

    return (
        <div className='overlay'>
            <div className="container">
                <div className="table-container">
                    <table className="bg-white text-center item-table">
                        <thead className='Theader'>
                            <tr>
                                <th colSpan="3">Ítems
                                    <button className="toggle-button" onClick={onClose}>
                                        <FaTimes />
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(item => {
                                const imageUrl = item.image ? getImageUrl(item.image.image) : noImageIcon;
                                return (
                                    <tr key={item.id}>
                                        <td><img src={imageUrl} alt={item.equipo} /></td>
                                        <td>
                                            <p>{item.equipo}</p>
                                        </td>
                                        <td>
                                            <div className='show-item-cantidad'>
                                                <span className="unit-text">{item.cantidad}</span>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <button className="show-checklist-button" onClick={() => handleShowCheckList(pedido)}>
                        Mostrar Checklist
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Showitems;
