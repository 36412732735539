import React from 'react';
import Layout from "../../hocs/Layout";
import GestionarUsuario from "../../components/GestionarUsuario";
const GestionarUsuarios = () => {
    return (
      <Layout>
        <main className="admin-background">
          <GestionarUsuario /></main>
      </Layout>
    );
  }

  export default (GestionarUsuarios);