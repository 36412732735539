import { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Sidebaradmin from '../components/Sidebaradmin';
import { connect } from 'react-redux';
import { refresh, check_authenticated, load_user } from '../redux/actions/auth';
import { get_pedidos } from '../redux/actions/pedidos';
import { setSearchTerm } from '../redux/actions/productos'; 
import { useNavigate, useLocation } from 'react-router-dom';
import React from 'react';

const Layout = ({ 
    refresh, 
    check_authenticated, 
    load_user, 
    get_pedidos, 
    setSearchTerm, // Add this prop
    usuario, 
    children 
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        refresh();
        check_authenticated();
        load_user();
        get_pedidos();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (usuario && usuario.tipo !== 'profesor') {
            setIsAdmin(true);
        }
    }, [usuario, navigate]);

    useEffect(() => {
        const searchPages = ['/carroproductos', '/ListaProductos'];
        if (!searchPages.includes(location.pathname)) {
            setSearchTerm('');
        }
    }, [location.pathname, setSearchTerm]);

    const handleSetSearchTerm = (term) => {
        setSearchTerm(term);
    };

    return (
        <>
             <Navbar setSearchTerm={handleSetSearchTerm} />
            {isAdmin ? <Sidebaradmin /> : <Sidebar />}
            {children}
        </>
    );
};

const mapStateToProps = state => ({
    usuario: state.Auth.user
});
export default connect(mapStateToProps, {
    refresh,
    check_authenticated,
    load_user,
    get_pedidos,
    setSearchTerm,
})(Layout);