import React, { useEffect, useState } from "react";
import Layout from "../../hocs/Layout";
import '../../css/Landing.css';
import '../../css/Sidebar.css';
import SolicitudList from "../../components/SolicitudList";
import { connect } from "react-redux";
import SolicitudPanol from "../../components/SolicitudPanol";

const Landing = ({ user }) => {
    localStorage.removeItem('idPedido');
    const [userTypeClass, setUserTypeClass] = useState(null);

    useEffect(() => {
        if (user) {
            const userType = user.tipo === 'profesor' ? 'landing' : 'landingpanol';
            setUserTypeClass(userType);
        }
    }, [user]);

    if (!user) {
        return (
            <Layout>
                <main className="loading">
                    <p>Loading user information...</p>
                </main>
            </Layout>
        );
    }

    return (
        <Layout>
            <main className={`${userTypeClass}`}>
                {user.tipo === 'profesor' ? (
                    <SolicitudList />
                ) : (
                    <SolicitudPanol />
                )}
            </main>
        </Layout>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
});

export default connect(mapStateToProps, {})(Landing);
