import {
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAIL,
    SET_SEARCH_TERM
} from '../actions/types';


const initialState = {
    productos: null,
    searchTerm: '',
};

export default function Productos(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                productos: payload
            }
        case GET_PRODUCTS_FAIL:
            return {
                ...state,
                productos: null
            }
        case SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.payload,
            };
        default:
            return state
    }
}