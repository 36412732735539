import React, { useState, useEffect, useCallback } from 'react';
import '../css/SolicitudList.css';
import { connect } from 'react-redux';
import { get_pedidos } from '../redux/actions/pedidos'; 
import { Link } from 'react-router-dom';
import en_proceso from '../images/en_proceso.svg';
import entregado from '../images/entregado.svg';
import listo_para_retiro from '../images/listo_para_retiro.svg';
import rechazado from '../images/rechazado.svg';
import eyeblue from '../images/eyeblue.svg';
import Showitems from './Showitems'; 
import EditIcon from '../images/Edit.svg';
import recibida from '../images/Recibida.svg';
import { MdDelete, MdOutlineInsertComment } from "react-icons/md";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import { BsDashLg } from "react-icons/bs";
import { ImBlocked } from "react-icons/im";
import Overlay from './Overlay'; 
import Paginator from './Paginator';
import CheckListProf from './CheckListProf';

const SolicitudList = ({ pedidos, get_pedidos, cantidadPaginas}) => {
  const [mostrarComponente, setMostrarComponente] = useState(false);
  const [idPedidoSeleccionado, setIdPedidoSeleccionado] = useState(null);
  const [selectedPedido, setSelectedPedido] = useState(null);
  const [showCheckList, setShowCheckList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayMessage, setOverlayMessage] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState({
    pageSize: 25,
    page: 1,
    estado: '',
    fechaCreation: '',
    fechaCreationDateOrder: '',
    estadoOrder: 'undefined',
    fechaCreationOrder: 'undefined',
    fechaCreationDateOrder: 'undefined',
  });

  const handleMostrarComponente = useCallback((pedidoId) => {
    setIdPedidoSeleccionado(pedidoId);
    setMostrarComponente(true);
  }, []);

  const handleOverlay = (message, confirmation = false) => {
    setOverlayMessage(message);
    setShowConfirmation(confirmation);
    setShowOverlay(true);
  };
  const handleShowItems = (pedido) => {
    setSelectedPedido(pedido);
    setMostrarComponente(true);
  };

  const handleShowCheckList = (pedido) => {
    setSelectedPedido(pedido);
    setShowCheckList(true);
  };
  const handleConfirmCancel = () => {
    // Implement cancel logic here
    setShowOverlay(false);
  };

  useEffect(() => {
    get_pedidos(selectedFilters);
  }, [selectedFilters]);

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem('idPedido');
    get_pedidos(selectedFilters)
      .then(() => setLoading(false))
      .catch(error => setLoading(false));
  }, [get_pedidos]);

  const obtenerImagenEstado = (estado) => {
    switch (estado) {
      case 'Recibida':
        return recibida;
      case 'En proceso':
        return en_proceso;
      case 'Entregado':
        return entregado;
      case 'Listo para retiro':
        return listo_para_retiro;
      case 'Rechazado':
        return rechazado;
      default:
        return null;
    }
  };
  const obtenerAccion = (estado, idPedido, descripcion) => {
    switch (estado) {
      case 'Recibida':
        return( <div><Link to={`/request/${idPedido}`} style={{ color: '#00A09A' }}> <img src={EditIcon} alt="" />  </Link>
         <div style={{ color: "#00A09A", cursor: "pointer" }} onClick={() => handleOverlay('¿Desea cancelar esta solicitud?', true)}>
            <MdDelete size={26} />
          </div>
        </div>)
      
      case 'Rechazado':
        return (
          <div style={{ color: "#00A09A", cursor: "pointer" }} onClick={() => handleOverlay( `Solicitud rechazada por administrador ${descripcion}`)}>
            <MdOutlineInsertComment size={26} />
          </div>
        );
  
      case 'En proceso':
        return (
          <div style={{ color: "#00A09A", cursor: "pointer" }} onClick={() => handleOverlay(`¿Desea cancelar esta solicitud? ${descripcion}`, true)}>
            <MdDelete size={26} />
          </div>
        );
      
      case 'Entregado':
        return (
          <div style={{ color: "#00A09A", cursor: "pointer" }} onClick={() => handleOverlay(`Entregado ${descripcion}`)}>
            <MdOutlineInsertComment size={26} />
          </div>
        );
      
      case 'Listo para retiro':
        return (
          <div style={{ color: "#00A09A", cursor: "pointer" }} onClick={() => handleOverlay(`Listo para retiro ${descripcion}`)}>
            <MdOutlineInsertComment size={26} />
          </div>
        );
      
      default:
        return (
          <div style={{ color: "#00A09A" }}>
            <ImBlocked size={24} />
          </div>
        );
    }
  };


  const handlePageSize = (e) => {
    const value = parseInt(e.target.value, 10);
    setSelectedFilters({ ...selectedFilters, pageSize: value });
  }

  const handlePageChange = (page) => {
    if (page) setSelectedFilters({ ...selectedFilters, page });
    
    const newPage =  selectedFilters.page + 1;

    setSelectedFilters({ ...selectedFilters, newPage});
  }

  const handleStateChange = (e) => {
    const value = e.target.value;
    setSelectedFilters({ ...selectedFilters, estado: value });
  }

  const ESTADOS = {
    undefined: 'Todos',
    'Recibida': 'Recibida',
    'En proceso': 'En proceso',
    'Entregado': 'Entregado',
    'Listo para retiro': 'Listo para retiro',
    'Rechazado': 'Rechazado',
  };

  const handleSortChange = (filter) => {
    const order = selectedFilters[filter];
    setSelectedFilters({
      ...selectedFilters,
      [filter]: order === 'undefined' ? 'asc' : order === 'asc' ? 'desc' : 'undefined',
    });
  };

  return (
    <div className='solicitudes'>
      <h2>Solicitudes</h2>
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <>
          <form className='filtros-form'>
            <label>
              Estado:
              <select
                className='filtros-form-select'
                name="estado" 
                id="estado" 
                value={selectedFilters.estado}
                onChange={handleStateChange} >
                  {Object.keys(ESTADOS).map(key => (
                    <option key={key} value={key === '' ? undefined : key}>
                      {ESTADOS[key]}
                    </option>
                  ))}
              </select>
            </label>
            <label>
              Fecha de creación:
              <input
                type="date"
                name="fechaCreation"
                id="fechaCreation"
                className="filtros-form-input"
                value={selectedFilters.fechaCreation}
                onChange={(e) => setSelectedFilters({ ...selectedFilters, fechaCreation: e.target.value })}
              />
            </label>
            <label>
              Fecha de inicio:
              <input
                type="date"
                name="fechaCreationDateOrder"
                id="fechaCreationDateOrder"
                className="filtros-form-input"
                value={selectedFilters.fechaCreationDateOrder}
                onChange={(e) => setSelectedFilters({ ...selectedFilters, fechaCreationDateOrder: e.target.value })}
              />
            </label>
            <label>
              Cant. solicitudes:
              <select
                className='filtros-form-select'
                name="pageSize" 
                id="pageSize" 
                value={selectedFilters.pageSize}
                onChange={handlePageSize} >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
              </select>
            </label>
          </form>
          <table className='tabla-solicitudes'>
            <thead className='tabla-cabecera-solicitudes'>
              <tr className='tabla-cabecera-solicitudes-titulos'>
                <th>Número de solicitud</th>
                <th onClick={() => handleSortChange('estadoOrder')} className='sortable'>
                  Estado 
                  { selectedFilters.estadoOrder === 'undefined' ? 
                  <BsDashLg/> : 
                  selectedFilters.estadoOrder === 'asc' ? 
                  <FaArrowUp/> : 
                  <FaArrowDown/> }
                </th>
                <th>Ítems</th>
                <th onClick={() => handleSortChange('fechaCreationOrder')} className='sortable'>
                  Fecha de creación pedido
                  { selectedFilters.fechaCreationOrder === 'undefined' ? 
                  <BsDashLg/> : 
                  selectedFilters.fechaCreationOrder === 'asc' ? 
                  <FaArrowUp/> : 
                  <FaArrowDown/> }
                </th>
                <th onClick={() => handleSortChange('fechaCreationDateOrder')} className='sortable'>
                  Fecha de inicio
                  { selectedFilters.fechaCreationDateOrder === 'undefined' ? 
                  <BsDashLg/> : 
                  selectedFilters.fechaCreationDateOrder === 'asc' ? 
                  <FaArrowUp/> : 
                  <FaArrowDown/> }
                </th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody className='tabla-cuerpo-solicitudes'>
              {pedidos &&
                pedidos !== null &&
                pedidos !== undefined &&
                pedidos.length !== 0 &&
                pedidos.map((pedido, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {pedido.estado}

                      </td>
                      <td>{pedido.items} <img src={eyeblue} alt='icon-eye-blue' id='Ojito' onClick={() => handleShowItems(pedido)} /> </td>
                      <td>{new Date(pedido.fecha_creacion).toLocaleDateString('es-ES')}</td>
                      <td>{new Date(pedido.pedido_creation_date).toLocaleDateString('es-ES')}</td>
                      <td>{obtenerAccion(pedido.estado, pedido.id, pedido.descripcion)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <Paginator totalPageCount={cantidadPaginas} currentPage={selectedFilters.page} handlePageChange={handlePageChange}/>
        </>
      )}
       {showOverlay && <Overlay message={overlayMessage} onClose={() => setShowOverlay(false)} showConfirmation={showConfirmation} onConfirm={handleConfirmCancel} />}
       {mostrarComponente && <Showitems onClose={() => setMostrarComponente(false)} pedido={selectedPedido} handleShowCheckList={handleShowCheckList} />}
       {showCheckList && (
        <CheckListProf
          mostrarCheckList={showCheckList}
          setMostrarCheckList={setShowCheckList}
          solicitud={selectedPedido}
        />
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  pedidos: state.Pedidos.pedidosUsuario,
  cantidadPaginas: state.Pedidos.totalPaginas
});

export default connect(mapStateToProps, { get_pedidos })(SolicitudList);
