import "./DropdownItem.css"

const DropdownItem = ({ children, onclick }) => {
    return (
        <div className='dropdown-item' onClick={onclick} >
            {children}
        </div>
    )
}

export default DropdownItem