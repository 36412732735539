import {
    GET_USERS_SUCCESS,
    GET_USERS_FAIL,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAIL,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    SET_AUTH_LOADING,
    REMOVE_AUTH_LOADING,
} from '../actions/types';

const initialState = {
    users: [],
    loading: false,
    error: null
};

export default function User (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_AUTH_LOADING:
            return { ...state, loading: true };
        case REMOVE_AUTH_LOADING:
            return { ...state, loading: false };
        case GET_USERS_SUCCESS:
            return { ...state, users: payload.Profesores, error: null };
        case CREATE_USER_SUCCESS:
        case UPDATE_USER_SUCCESS:
        case DELETE_USER_SUCCESS:
            return { ...state, error: null }; // Handle additional cases as needed
        case GET_USERS_FAIL:
        case CREATE_USER_FAIL:
        case UPDATE_USER_FAIL:
        case DELETE_USER_FAIL:
            return { ...state, error: 'Error in operation' };
        default:
            return state;
    }
}
