import { useState, useEffect } from 'react'
import { passwordChange } from '../redux/actions/auth'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import '../css/Contrasena.css';

const Contrasena = ({ passwordChange, loading, error, user }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [formData, setFormData] = useState({
    password: '',
    passwordNew: '',
  })
  const [submitError, setSubmitError] = useState('')
  const [submitSuccess, setSubmitSuccess] = useState('')
  const { password, passwordNew } = formData
  const [passwordValidate, setPasswordValidate] = useState('')

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value })

  const onSubmit = async (e) => {
    e.preventDefault()
    if (!password || !passwordNew || !passwordValidate) {
      setSubmitError('Por favor, ingresa contraseña, nueva contraseña y validación.')
      return
    }
    if (passwordNew !== passwordValidate) {
      setSubmitError('Las contrasenas no coinciden. Por favor, ingresa una vez mas.')
      return
    }
    try {
      const result = await passwordChange(user?.id, password, passwordNew, passwordValidate)
      if (result.success) {
        window.scrollTo(0, 0)
        setSubmitError('')
        setSubmitSuccess('Su contraseña ha sido cambiada con exito.')
      } else {
        setSubmitSuccess('')
        setSubmitError('Credenciales inválidas. Por favor, inténtalo de nuevo.')
      }
    } catch (err) {
      setSubmitError('Error al cambiar contraseña. Por favor, inténtalo de nuevo.')
    }
  }
  if (submitSuccess != '') {
    return <Navigate to='/landing' />
  }

  return (
    <div className='contrasena'>
    <div className='container'>
      <form onSubmit={onSubmit} className='formulario text-center'>
        <h2 className='formulario-h2'>Cambio de contraseña</h2>
        {(submitError || error) && <p style={{ color: 'red' }}>{submitError || error}</p>}
        {(submitSuccess || error) && <p style={{ color: 'green' }}>{submitSuccess || error}</p>}
        <div className='form-group'>
          <input
            id='password'
            name='password'
            type='password'
            className='formulario-input'
            placeholder='Contraseña actual'
            onChange={onChange}
          />
        </div>
        <div className='form-group'>
          <input
            id='passwordNew'
            name='passwordNew'
            type='password'
            className='formulario-input'
            placeholder='Contraseña nueva'
            onChange={onChange}
          />
        </div>
        <div className='form-group'>
          <input
            id='passwordValidate'
            name='passwordValidate'
            type='password'
            className='formulario-input'
            placeholder='Repetir contraseña'
            onChange={(event) => setPasswordValidate(event.target.value)}
          />
        </div>
        <div className='my-4 d-flex justify-content-center'>
          <button
            style={{ fontFamily: 'Nunito' }}
            type='submit'
            className='botoncito btn-iniciar-sesion'
          >
            Enviar
          </button>
        </div>
      </form>
    </div></div>
  )
}
const mapStateToProps = (state) => ({
  loading: state.Auth.loading,
  error: state.Auth.error,
  user: state.Auth.user,
})

export default connect(mapStateToProps, { passwordChange })(Contrasena)
