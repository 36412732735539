import axios from 'axios';
import {
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAIL,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAIL,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAIL,
    SET_SEARCH_TERM
} from './types';

export const setSearchTerm = (term) => ({
    type: SET_SEARCH_TERM,
    payload: term,
  });

export const get_productos = (horarios_selected) => async dispatch => {
    const horariosSelectedInt = horarios_selected?.map(item => +item)

    const config = {
        headers: {
            'Accept': 'application/json'
        },
        params: { 
            horarios_selected: horariosSelectedInt
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/productos/`, config);

        if (res.status === 200) {
            dispatch({
                type: GET_PRODUCTS_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_PRODUCTS_FAIL
            });
        }

    } catch (err) {
        dispatch({
            type: GET_PRODUCTS_FAIL
        });
    }
}

export const get_productos_admin = () => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/admin/productos/`, config);
        
        if (res.status === 200) {
            dispatch({
                type: GET_PRODUCTS_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_PRODUCTS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_PRODUCTS_FAIL
        });
    }
}
export const updateProducto = (id, productData) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`
        }
    };

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/admin/productos/${id}/`, productData, config);

        if (res.status === 200) {
            dispatch({
                type: UPDATE_PRODUCT_SUCCESS,
                payload: res.data
            });
            return true;
        }
    } catch (err) {
        dispatch({
            type: UPDATE_PRODUCT_FAIL
        });
        return false;
    }
};
export const deleteProducto = (id) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`
        }
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/admin/productos/${id}/`, config);

        if (res.status === 204) {  // 204 No Content means the deletion was successful
            dispatch({
                type: DELETE_PRODUCT_SUCCESS,
                payload: id
            });
            return true;
        }
    } catch (err) {
        dispatch({
            type: DELETE_PRODUCT_FAIL
        });
        return false;
    }
};