import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import '../css/Solicitud.css';
import Dropdown from './Dropdown/Dropdown';
import DropdownItem from './DropdownItem/DropdownItem';
import { getSolicitud, updateTaller, updateDescripcion, updateUsuario } from '../redux/actions/solicitud';
import { listTalleres } from '../redux/actions/talleres';

const SolicitudForm = ({ idPedido }) => {
  const dispatch = useDispatch();
  const { taller: tller, descripcion: descrip } = useSelector((state) => state.Solicitud);
  const { user: usuario } = useSelector((state) => state.Auth);
  const { talleres, loading, error } = useSelector((state) => state.Taller);

  const [taller, setTaller] = useState(sessionStorage.getItem('selectedTaller') || tller || '');
  const [descripcion, setDescripcion] = useState(sessionStorage.getItem('descripcionTaller') || descrip || '');
  const [buttonText, setButtonText] = useState(taller || 'Seleccione un Taller');
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    dispatch(listTalleres());
    if (idPedido) {
      dispatch(getSolicitud(idPedido));
    }
  }, [dispatch, idPedido]);

  useEffect(() => {
    if (usuario && usuario !== null) {
      dispatch(updateUsuario(usuario));
    }
  }, [dispatch, usuario]);

  useEffect(() => {
    if (descrip) {
      setDescripcion(descrip);

      sessionStorage.setItem('descripcionTaller',descrip)
    }
    if (tller) {
      setTaller(tller);
      setButtonText(tller);
      sessionStorage.setItem('selectedTaller', tller);
    }
  }, [descrip, tller]);

  const handleTaller = (nombre) => {
    setButtonText(nombre);
    setTaller(nombre);
    sessionStorage.setItem('selectedTaller', nombre);
    dispatch(updateTaller(nombre));
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleDescripcionChange = (value) => {
    setDescripcion(value);
    sessionStorage.setItem('descripcionTaller',value)
    dispatch(updateDescripcion(value));
  };

  return (
    <div className="container">
      <form action="" className="bg-white text-center ">
        <h3>Crear solicitud</h3>
        <div className="content" focused={focused.toString()}>
          <Dropdown
            buttonText={buttonText}
            content={
              <>
                {loading && <DropdownItem>Cargando...</DropdownItem>}
                {error && <DropdownItem>Error: {error}</DropdownItem>}
                {!loading && !error && talleres && talleres.map((item) => (
                  <DropdownItem
                    key={item.id}
                    onclick={() => handleTaller(item.nombre)}
                  >
                    {item.nombre}
                  </DropdownItem>
                ))}
              </>
            }
          />
        </div>
        <span className='error-message'>Debe seleccionar una opción</span>
        <input
          className="my-3"
          type="text"
          placeholder="Descripción"
          value={descripcion}
          required
          onBlur={handleFocus}
          focused={focused.toString()}
          onChange={(e) => handleDescripcionChange(e.target.value)}
        />
        <span className='error-message'>La descripción no puede estar vacía</span>
        <div className="mt-3 d-flex justify-content-between container-buttons">
          <Link to="/landing"><button className='button'>Volver</button></Link>
          <Link to={`/fechas/`}>
            <button className='button'>Siguiente</button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default SolicitudForm;