import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../css/AdminProductCard.css';
import noImageIcon from '../images/no-image-icon.svg';
import AdminProductModal from './AdminProductModal.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

const AdminProductCard = React.memo(({ producto, isOpen, onOpen, onClose, deleteProducto }) => {
    const [imageUrl, setImageUrl] = useState(null);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const cardRef = useRef(null);
    const imageRef = useRef(null);

    useEffect(() => {
        if (producto.images && producto.images.length > 0) {
            setThumbnailUrl(`${process.env.REACT_APP_API_URL}${producto.images[0].thumbnail}`);
            setImageUrl(`${process.env.REACT_APP_API_URL}${producto.images[0].image}`);
        } else {
            setThumbnailUrl(null);
            setImageUrl(null);
        }
        setIsImageLoaded(false);
    }, [producto]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            {
                root: null,
                rootMargin: '100px',
                threshold: 0.1
            }
        );

        if (cardRef.current) {
            observer.observe(cardRef.current);
        }

        return () => {
            if (cardRef.current) {
                observer.unobserve(cardRef.current);
            }
        };
    }, []);

    const handleImageLoad = useCallback(() => {
        setIsImageLoaded(true);
    }, []);

    useEffect(() => {
        const img = imageRef.current;

        if (isVisible && imageUrl && img) {
            if (img.complete) {
                handleImageLoad();
            } else {
                img.addEventListener('load', handleImageLoad);
            }

            return () => {
                if (img) {
                    img.removeEventListener('load', handleImageLoad);
                }
            };
        }
    }, [isVisible, imageUrl, handleImageLoad]);

    const handleDelete = useCallback(async (e) => {
        e.stopPropagation();
        const confirmDelete = window.confirm(`Seguro que quieres eliminar ${producto.equipo}?`);
        if (confirmDelete) {
            const success = await deleteProducto(producto.id);
            if (success) {
                window.location.reload();
            } else {
                console.error('Error deleting product');
            }
        }
    }, [producto.equipo, producto.id, deleteProducto]);

    const handleEdit = useCallback((e) => {
        e.stopPropagation();
        onOpen();
    }, [onOpen]);

    const isOutOfStock = producto.cantidad === 0;
    return (
        <div className="card-wrapper" ref={cardRef}>
            <div className="card">
                {isOutOfStock && <div className="overlay">Agotado</div>}
                <div className="image-container">
                    {thumbnailUrl && (
                        <img 
                            src={thumbnailUrl} 
                            alt={producto.equipo || 'Producto'} 
                            className="card-img-top thumbnail"
                        />
                    )}
                    {imageUrl && (
                        <img 
                            ref={imageRef}
                            src={imageUrl} 
                            alt={producto.equipo || 'Producto'} 
                            className={`card-img-top full-image ${isImageLoaded ? 'loaded' : ''}`}
                        />
                    )}
                    {!thumbnailUrl && !imageUrl && (
                        <img src={noImageIcon} alt="No image available" className="no-image-icon card-img-top" />
                    )}
                </div>
                <h6 className="card-title2">{producto.marca ? producto.marca : 'Marca no disponible'}</h6>
                <div className="card-body">
                
                    <h5 className="card-title">{producto.equipo}</h5>
                       
                        <div className="icon-buttons">
                            <FontAwesomeIcon 
                                icon={faEdit} 
                                className="edit-icon"
                                onClick={handleEdit} 
                            />
                             <p className="card-text"><b>{producto.cantidad}</b></p>
                            <FontAwesomeIcon 
                                icon={faTrash} 
                                className="trash-icon"
                                onClick={handleDelete} 
                            />
                    </div>
                </div>
            </div>
            {isOpen && (
                <AdminProductModal
                    producto={producto}
                    onClose={onClose}
                />
            )}
        </div>
    );
});

export default AdminProductCard; 