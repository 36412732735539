import React, { useState } from 'react';
import '../css/AddProductForm.css';

const AddProduct = () => {
    const [formData, setFormData] = useState({
        categoria: '',
        equipo: '',
        marca: '',
        modelo: '',
        cantidad: '',
        estado: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleImageChange = (e) => {
        setFormData({
            ...formData,
            imagen: e.target.files[0]
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí puedes manejar el envío del formulario, por ejemplo, enviándolo a una API
        console.log(formData);
    };

    return (
        <form className="add-product-form" onSubmit={handleSubmit}>
            <h2>Agregar nuevo producto</h2>
            <div className="form-container">
                <div className="form-fields">
                    <div className="formulario-grupo">
                        <input
                            type="text"
                            name="categoria"
                            value={formData.categoria}
                            onChange={handleChange}
                            placeholder="Categoría"
                            className="input-full-width"
                        />
                    </div>
                    <div className="formulario-grupo">
                        <input
                            type="text"
                            name="equipo"
                            value={formData.equipo}
                            onChange={handleChange}
                            placeholder="Equipo"
                            className="input-full-width"
                        />
                    </div>
                    <div className="formulario-grupo">
                        <input
                            type="text"
                            name="marca"
                            value={formData.marca}
                            onChange={handleChange}
                            placeholder="Marca"
                            className="input-full-width"
                        />
                    </div>
                    <div className="formulario-grupo">
                        <input
                            type="text"
                            name="modelo"
                            value={formData.modelo}
                            onChange={handleChange}
                            placeholder="Modelo/ Color/ Ubicación/ Año"
                            className="input-full-width"
                        />
                    </div>
                    <div className="formulario-grupo">
                        <input
                            type="number"
                            name="cantidad"
                            value={formData.cantidad}
                            onChange={handleChange}
                            placeholder="Cantidad"
                            className="input-full-width"
                        />
                    </div>
                    <div className="formulario-grupo">
                        <input
                            type="text"
                            name="estado"
                            value={formData.estado}
                            onChange={handleChange}
                            placeholder="Estado activo o inactivo"
                            className="input-full-width"
                        />
                    </div>
                </div>
                <div className="form-image">
                    <div className="formulario-grupo">
                        <label>Imagen</label>
                        <input type="file" accept="image/*" onChange={handleImageChange} className="input-full-width" />
                        {formData.imagen && <img src={URL.createObjectURL(formData.imagen)} alt="Vista previa" className="image-preview" />}
                    </div>
                    <div className="button-container">
                        <button type="submit" className="submit-button">Guardar datos</button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default AddProduct;
