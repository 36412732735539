import { combineReducers } from 'redux';
import Auth from './auth';
import Pedidos from './pedidos';
import Productos from './productos'
import Categorias from './categorias';
import Carrito from './carrito'
import Solicitud from './solicitud'
import User from './user'
import Taller from './taller'
import Resoluciones from './resoluciones'
export default combineReducers({
    Auth,
    Pedidos,
    Productos,
    Categorias,
    Carrito, 
    Solicitud,
    User,
    Taller,
    Resoluciones
})