import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { addItemSolicitud, deleteItemSolicitud } from "../redux/actions/solicitud";
import ModalProduct from './ModalProduct';
import ImageCarousel from './ImageCarousel';
import agregarSvg from '../images/agregar.svg';
import basuraSvg from '../images/basura.svg';
import noImageIcon from '../images/no-image-icon.svg';
import '../css/ProductCard.css';
const ProductCard = React.memo(({ carrito, producto, itemsCarrito, addItemSolicitud, deleteItemSolicitud }) => {
  const [cantidad, setCantidad] = useState(0);
  const [showAddButton, setShowAddButton] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const cardRef = useRef(null);
  const imageRef = useRef(null);

  const { imageUrl, thumbnailUrl, imagesUrl } = useMemo(() => {
    const getImageUrl = (imagePath) => {
      if (!imagePath) return null;
      return `${process.env.REACT_APP_API_URL}${imagePath}`;
    };

    if (producto.images && producto.images.length > 0) {
      return {
        thumbnailUrl: getImageUrl(producto.images[0].thumbnail),
        imageUrl: getImageUrl(producto.images[0].image),
        imagesUrl: producto.images.map(img => getImageUrl(img.image))
      };
    } else {
      return {
        thumbnailUrl: null,
        imageUrl: null,
        imagesUrl: [noImageIcon]
      };
    }
  }, [producto.images]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '100px',
        threshold: 0.1
      }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  const handleImageLoad = useCallback(() => {
    setIsImageLoaded(true);
  }, []);

  useEffect(() => {
    const img = imageRef.current;

    if (isVisible && imageUrl && img) {
      if (img.complete) {
        handleImageLoad();
      } else {
        img.addEventListener('load', handleImageLoad);
      }

      return () => {
        if (img) {
          img.removeEventListener('load', handleImageLoad);
        }
      };
    }
  }, [isVisible, imageUrl, handleImageLoad]);
  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  useEffect(() => {
    if (itemsCarrito) {
      const itemInCarrito = itemsCarrito.find(item => item.producto.id === producto.id);
      if (itemInCarrito) {
        setCantidad(itemInCarrito.cantidad);
        setShowAddButton(true);
      } else {
        setCantidad(0);
        setShowAddButton(false);
      }
    } else {
      setCantidad(0);
      setShowAddButton(false);
    }
  }, [itemsCarrito, producto.id, carrito]);

  const incrementarCantidad = useCallback(() => {
    if (cantidad < producto.cantidad) {
      addItemSolicitud(producto);
    } else {
      console.log(`No se puede agregar más del producto ${producto.id}. Cantidad máxima alcanzada.`);
    }
  }, [cantidad, producto, addItemSolicitud]);

  const decrementarCantidad = useCallback(() => {
    if (cantidad > 0) {
      const nuevaCantidad = cantidad - 1;
      setCantidad(nuevaCantidad);
      deleteItemSolicitud(producto.id);
      if (nuevaCantidad === 0) {
        setShowAddButton(false);
      }
    }
  }, [cantidad, producto.id, deleteItemSolicitud]);

  const isOutOfStock = producto.cantidad === 0 || producto.cantidad === null;

  const addButtonClick = useCallback(() => {
    setShowAddButton(true);
    incrementarCantidad();
  }, [setShowAddButton, incrementarCantidad]);

  const renderAddButton = useMemo(() => {
    if (showAddButton) {
      return (
        <div className='add-button-producto'>
          <button onClick={decrementarCantidad}>
            <img src={basuraSvg} alt='Quitar producto' />
          </button>
          <span>{cantidad}</span>
          <button onClick={incrementarCantidad}>
            <img src={agregarSvg} alt='Agregar producto' />
          </button>
        </div>
      );
    } else if (!isOutOfStock) {
      return (
        <div className='add-button' onClick={addButtonClick}>Agregar</div>
      );
    }
    return null;
  }, [showAddButton, isOutOfStock, cantidad, decrementarCantidad, incrementarCantidad, addButtonClick]);

  return (
    <div className={`product-card-wrapper ${isOutOfStock ? 'product-out-of-stock' : ''}`} ref={cardRef}>
      <div className="product-card" onClick={openModal}>
        <div className="product-image-container">
          {thumbnailUrl && (
            <img 
              src={thumbnailUrl} 
              alt={producto.equipo || 'Producto'} 
              className="product-card-img product-thumbnail"
            />
          )}
          {imageUrl && (
            <img 
              ref={imageRef}
              src={imageUrl} 
              alt={producto.equipo || 'Producto'} 
              className={`product-card-img product-full-image ${isImageLoaded ? 'product-image-loaded' : ''}`}
            />
          )}
          {!thumbnailUrl && !imageUrl && (
            <img src={noImageIcon} alt="No image available" className="product-no-image product-card-img" />
          )}
        </div>
        {isOutOfStock && <div className="product-overlay"><p>No disponible</p></div>}
        
        <h6 className="card-title2">{producto.marca ? producto.marca : 'Marca no disponible'}</h6>
                <div className="card-body">
                    <h5 className="card-title">{producto.equipo ? producto.equipo : 'Nombre no disponible'}</h5>
                        <div className="icon-buttons">
                             <p className="card-text"><b>{producto.cantidad}</b></p>
                    </div>
                </div>
            </div>
      {renderAddButton}
     <ModalProduct isOpen={isModalOpen} onClose={closeModal}>
     {imagesUrl.length > 1 ? (
          <ImageCarousel images={imagesUrl} />
        ) : (
          <img src={imagesUrl[0]} alt={producto.equipo || 'Producto'} className="modal-image" />
        )}
        {isOutOfStock && <div className="overlay text-center"><p>No disponible</p></div>}
        <h2>{producto.equipo}</h2>
        <p><b>Marca:</b> {producto.marca || "No disponible"}</p>
        <p><b>Descripción:</b> {producto.descripcion || "No disponible"}</p>
        <p><b>Estado:</b> {producto.estado || "No disponible"}</p>
        <p><b>Sección:</b> {producto.seccion || "No disponible"}</p>
        <p><b>Fecha de Vencimiento:</b> {producto.fecha_vencimiento || "No disponible"}</p>
        <p><b>Proveedor:</b> {producto.proveedor || "No disponible"}</p>
        <p><b>Observación:</b> {producto.observacion || "No disponible"}</p>
        <p className="card-text"><b>Cantidad: </b> {producto.cantidad} {producto.cantidad ? producto.cantidad >1 ? 'Unidades' : 'Unidad': 'No disponible'}</p>
        {showAddButton ? (
          <div className='add-button-producto'>
            <button onClick={decrementarCantidad}>
              <img src={basuraSvg} alt='Quitar producto' />
            </button>
            <span>{cantidad}</span>
            <button onClick={incrementarCantidad}>
              <img src={agregarSvg} alt='Agregar producto' />
            </button>
          </div>
        ) : (
          isOutOfStock ? null : (
            <div className='add-button agregar-button' onClick={() => { setShowAddButton(true); incrementarCantidad(); }}>Agregar</div>
          )
        )}
      </ModalProduct>
    </div>
  );
});

const mapStateToProps = (state) => ({
  carrito: state.Solicitud.carrito,
});

export default connect(mapStateToProps, {
  addItemSolicitud,
  deleteItemSolicitud,
})(ProductCard);