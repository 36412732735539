import {
    GET_TALLERES_SUCCESS,
    GET_TALLERES_FAIL,
    CREATE_TALLER_SUCCESS,
    CREATE_TALLER_FAIL,
    UPDATE_TALLER_SUCCESS,
    UPDATE_TALLER_FAIL,
    DELETE_TALLER_SUCCESS,
    DELETE_TALLER_FAIL,
} from '../actions/types';

const initialState = {
    talleres: [],
    loading: false,
    error: null
};

export default function Taller (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_TALLERES_SUCCESS:
            return { ...state, talleres: payload, error: null };
        case CREATE_TALLER_SUCCESS:
        case UPDATE_TALLER_SUCCESS:
        case DELETE_TALLER_SUCCESS:
            return { ...state, error: null }; // Handle additional cases as needed
        case GET_TALLERES_FAIL:
        case CREATE_TALLER_FAIL:
        case UPDATE_TALLER_FAIL:
        case DELETE_TALLER_FAIL:
            return { ...state, error: 'Error in operation' };
        default:
            return state;
    }
}
