import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listUsers, createUser, updateUser, deleteUser } from '../redux/actions/user';
import GestionarUsuarioModal from './GestionarUsuarioModal';
import '../css/GestionarUsuario.css';

const GestionarUsuario = () => {
  const dispatch = useDispatch();
  const { users, loading, error } = useSelector(state => state.User);

  const [formValues, setFormValues] = useState({
    id: '',
    nombre: '',
    email: '',
    password: '',
    isStaff: false,
    estado: true,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    dispatch(listUsers());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleCreateUser = (e) => {
    e.preventDefault();
    const { nombre, email, password, isStaff, estado } = formValues;
    dispatch(createUser(nombre, email, password, isStaff, estado)).then(() => {
      dispatch(listUsers()); // Reload the user list
      closeModal();
    });
  };
  const handleUpdateUser = (e) => {
    e.preventDefault();
    const { id, nombre, email, isStaff, estado,password } = formValues;
    dispatch(updateUser(id, nombre, email, isStaff, estado,password)).then(() => {
      dispatch(listUsers()); // Reload the user list
      closeModal();
    });
  };

  const openCreateModal = () => {
    setIsEditMode(false);
    setFormValues({
      id: '',
      nombre: '',
      email: '',
      password: '',
      isStaff: false,
      estado: true,
    });
    setIsModalOpen(true);
  };

  const openEditModal = (user) => {
    setIsEditMode(true);
    setFormValues({
      id: user.id,
      nombre: user.nombre,
      email: user.email,
      isStaff: user.is_staff,
      estado: user.estado,
      password: '', // We don't set the password for editing
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
    setFormValues({
      id: '',
      nombre: '',
      email: '',
      password: '',
      isStaff: false,
      estado: true,
    });
  };

  const handleDeleteUser = (id) => {
    dispatch(deleteUser(id)).then(() => {
      dispatch(listUsers());
    });
  };
  const sortedUsers = users ? [...users].sort((a, b) => a.nombre.localeCompare(b.nombre)) : [];
  return (
    <div className="GU-container">
      <div className="GU-inner-container">
        <h2 className="GU-title">Gestionar Usuarios</h2>
        
        {loading && <p className="GU-loading">Loading...</p>}
        {error && <p className="GU-error">Error: {error}</p>}
        
        <button className="GU-create-button" onClick={openCreateModal}>
          Crear Usuario
        </button>

        <GestionarUsuarioModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmit={isEditMode ? handleUpdateUser : handleCreateUser}
          formValues={formValues}
          handleInputChange={handleInputChange}
          isEditMode={isEditMode}
        />

        <h3 className="GU-subtitle">Lista de Usuarios</h3>
        <ul className="GU-user-list">
          {sortedUsers.map(user => (
            <li key={user.id} className="GU-user-item">
              <div className="GU-user-main">
                <div className="GU-user-info">
                  <span>{user.nombre} <br></br>{user.email}</span>
                </div>
                <div className="GU-user-actions-and-tags">
                  <div className="GU-user-tags">
                    {user.is_staff && <span className="GU-tag GU-tag-admin">Admin</span>}
                    <span className={`GU-tag GU-tag-status ${user.estado ? 'GU-tag-active' : 'GU-tag-inactive'}`}>
                      {user.estado ? 'Activo' : 'Inactivo'}
                    </span>
                  </div>
                  <div className="GU-user-actions">
                    <button className="GU-edit-button" onClick={() => openEditModal(user)}>Editar</button>
                    <button className="GU-delete-button" onClick={() => handleDeleteUser(user.id)}>Eliminar</button>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default GestionarUsuario;