import React, { useState, useEffect, useId } from 'react';
import { LiaShoppingCartSolid } from "react-icons/lia";
import { connect } from 'react-redux';
import "../css/ProductoListaCarrito.css";
import { FaTimes } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import Checklist from './CheckList';
import { addItemSolicitud, deleteItemSolicitud } from '../redux/actions/solicitud';
import ProductoCarrito from './ProductoCarrito';

const ProductoListaCarrito = ({ carrito }) => {
  const cartCheckboxId = useId();
  const [productos, setProductos] = useState([]);
  const location = useLocation();
  const [showCheckList, setShowCheckList] = useState(false);

  useEffect(() => {
    if (carrito && carrito.items) {
      setProductos(carrito.items);
    }
  }, [carrito.items]);
  const mostrarCheckList = () => {
    setShowCheckList(true);
  };

  const isCarroProductosPage = location.pathname === "/carroproductos";

  const handleCartToggle = () => {
    const checkbox = document.getElementById(cartCheckboxId);
    if (checkbox) {
      checkbox.checked = !checkbox.checked;
    }
  };


  return (
    <>
      {isCarroProductosPage && (
       <label htmlFor={cartCheckboxId} className='cart-button'>
       <LiaShoppingCartSolid size={38} />
       {productos.length > 0 && (
         <span className='cart-item-count'>{productos.length}</span>
       )}
     </label>
      )}
      <input id={cartCheckboxId} type='checkbox' hidden />
      <aside className='cart'>
        <h2>Tienes {productos.length} productos</h2>
        <FaTimes className='icono-cerrar' size={32} onClick={handleCartToggle} />
        <div className='container-productos'>
          <ul className='lista-productos-carrito'>
            {productos.map((producto, index) => (
                <ProductoCarrito
                key={index}
                producto={producto}
                itemsCarrito={carrito.items}
            />
            ))}
          </ul>
        </div>
        <button className='button-siguiente' onClick={mostrarCheckList}>CONFIRMAR SOLICITUD</button>
      </aside>
      <Checklist  mostrarCheckList={showCheckList} setMostrarCheckList={setShowCheckList} />
    </>
  );
};

const mapStateToProps = state => ({
  carrito: state.Solicitud.carrito,
});

export default connect(mapStateToProps, {
  addItemSolicitud,
  deleteItemSolicitud
})(ProductoListaCarrito);
