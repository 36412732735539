import React from 'react';
import Layout from "../../hocs/Layout";
import AddProductForm from '../../components/AddProduct'; // Ajusta la ruta según tu estructura
import "./../../css/AgregarProducto.css";

const AgregarProductos = () => {
    return (
        <Layout>
            <main className="BackgroundImage">
                <AddProductForm />
            </main>
        </Layout>
    );
};

export default AgregarProductos;
