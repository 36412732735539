// Overlay.js
import React, { useRef } from 'react';
import '../css/Overlay.css';
import { FaTimes } from 'react-icons/fa';

const Overlay = ({ message, onClose, showConfirmation, onConfirm }) => {
    const overlayRef = useRef();

    const handleClickOutside = (e) => {
        if (overlayRef.current && !overlayRef.current.contains(e.target)) {
            onClose();
        }
    };

    return (
        <div className='overlay' onClick={handleClickOutside}>
            <div className="overlay-container" ref={overlayRef}>
                <div>{message}</div>
                {showConfirmation && (
                    <div className="confirmation-buttons">
                        <button onClick={onConfirm}>Si</button>
                        <button onClick={onClose}>No</button>
                    </div>
                )}
                {!showConfirmation && (
                    <button className="close-button" onClick={onClose}>
                        <FaTimes />
                    </button>
                )}
            </div>
        </div>
    );
};

export default Overlay;

