import React, { useState, useEffect, useCallback } from 'react';
import '../css/SolicitudList.css';
import { connect } from 'react-redux';
import { call_pedidos, procesar_pedido } from '../redux/actions/resoluciones';
import eyeblue from '../images/eyeblue.svg';
import Showitems from './Showitems';
import CheckListPanol from './CheckListPanol';
import Paginator from './Paginator';
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import { BsDashLg } from "react-icons/bs";
import ConfirmationModal from './ConfirmationModal';

const SolicitudList = ({ pedidos, call_pedidos, cantidadPaginas, procesar_pedido }) => {
  const [mostrarComponente, setMostrarComponente] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [idPedidoSeleccionado, setIdPedidoSeleccionado] = useState(null);
  const [showCheckList, setShowCheckList] = useState(false);
  const [selectedPedido, setSelectedPedido] = useState(null);
  const [loading, setLoading] = useState(true);


  const [selectedFilters, setSelectedFilters] = useState({
    pageSize: 25,
    page: 1,
    estado: 'undefined',
    nombreCorreo: '',
    fechaCreacion: 'undefined',
    fechaCreationDate: 'undefined',
    estadoOrder: 'undefined',
    fechaCreationOrder: 'undefined',
    fechaCreationDateOrder: 'undefined',
  });
  const handleConfirmAction = (pedidoId, newState) => {
    setConfirmAction({ pedidoId, newState });
    setIsConfirmModalOpen(true);
  };

  const handleProcesarPedido = () => {
    if (confirmAction) {
      procesar_pedido(confirmAction.pedidoId, confirmAction.newState);
    }
    closeConfirmModal();
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
    setConfirmAction(null);
  };
  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, wait);
    };
  };
  const debouncedCallPedidos = useCallback(debounce(call_pedidos, 300), [call_pedidos]);

  const handleMostrarComponente = useCallback((pedidoId) => {
      setIdPedidoSeleccionado(pedidoId);
      setMostrarComponente(true);
  }, []);

  const handleShowItems = (pedido) => {
    setSelectedPedido(pedido);
    setMostrarComponente(true);
  };

  const handleShowCheckList = (pedido) => {
    setSelectedPedido(pedido);
    setShowCheckList(true);
  };

  useEffect(() => {
    debouncedCallPedidos(selectedFilters);
  }, [selectedFilters, debouncedCallPedidos]);


  useEffect(() => {
    window.scrollTo(0, 0);
    call_pedidos(selectedFilters)
      .then(() => setLoading(false))
      .catch(error => {
        setLoading(false);
      });
  }, [call_pedidos]);

  const estadoProfesorPanol = (estado) => {
    switch (estado) {
      case 'Recibida':
        return <p>Recibida</p>;
      case 'En proceso':
        return <p>En proceso</p>;
      case 'Listo para retiro':
        return <p>Listo para retiro</p>;
      case 'Entregado':
        return <p>Entregado</p>;
      case 'Rechazado':
        return <p>Rechazado</p>;
      default:
        return <p>{estado}</p>;
    }
  };

 
  const obtenerAccion = (estado, pedido) => {
    switch (estado) {
      case 'Recibida':
        return <button className='panol-check-btn' onClick={() => handleShowCheckList(pedido)}>Procesar</button>;
      case 'En proceso':
        return <button className='panol-check-btn' onClick={() => handleConfirmAction(pedido.id, 'Listo para retiro')}>Marcar listo</button>;
      case 'Listo para retiro':
        return <button className='panol-check-btn' onClick={() => handleConfirmAction(pedido.id, 'Entregado')}>Entregar</button>;
      case 'Entregado':
        return 'Entregado';
      case 'Rechazado':
        return 'Rechazado';
      default:
        return 'Estado desconocido';
    }
  };
  const hideDate = (estado) => {
    return estado === 'Recibida';
  }

  const handlePageSize = (e) => {
    const value = parseInt(e.target.value, 10);
    setSelectedFilters({ ...selectedFilters, pageSize: value });
  }

  const handlePageChange = (page) => {
    if (page) setSelectedFilters({ ...selectedFilters, page });
    
    const newPage =  selectedFilters.page + 1;

    setSelectedFilters({ ...selectedFilters, newPage});
  }

  const handleNombreCorreoChange = (e) => {
    // add timeout to prevent calling the API too many times
    const value = e.target.value;
    setSelectedFilters({ ...selectedFilters, nombreCorreo: value });
  }

  const handleStateChange = (e) => {
    const value = e.target.value;
    setSelectedFilters({ ...selectedFilters, estado: value });
  }

  const ESTADOS = {
    undefined: 'Todos',
    'Recibida': 'Recibida',
    'En proceso': 'En proceso',
    'Entregado': 'Entregado',
    'Listo para retiro': 'Listo para retiro',
    'Rechazado': 'Rechazado',
  };

  const handleSortChange = (filter) => {
    const order = selectedFilters[filter];
    setSelectedFilters({
      ...selectedFilters,
      [filter]: order === 'undefined' ? 'asc' : order === 'asc' ? 'desc' : 'undefined',
    });
  };

  return (
    <div className='solicitudes'>
      <h2>Solicitudes</h2>
      {loading ? (
        <p>Cargando...</p>
      ) : (

        <>
          <form className='filtros-form'>
            <label htmlFor="nombreCorreo">
              Nombre o correo
              <input 
                type="text" 
                placeholder="" 
                className='filtros-form-input' 
                name="nombreCorreo" 
                id="nombreCorreo"
                value={selectedFilters.nombreCorreo}
                onChange={handleNombreCorreoChange}/>
            </label>
            <label>
              Estado:
              <select
                className='filtros-form-select'
                name="estado" 
                id="estado" 
                value={selectedFilters.estado}
                onChange={handleStateChange} >
                  {Object.keys(ESTADOS).map(key => (
                    <option key={key} value={key === '' ? undefined : key}>
                      {ESTADOS[key]}
                    </option>
                  ))}
              </select>
            </label>
            <label>
              Fecha de creación:
              <input
                type="date"
                name="fechaCreacion"
                id="fechaCreacion"
                className="filtros-form-input"
                value={selectedFilters.fechaCreacion}
                onChange={(e) => setSelectedFilters({ ...selectedFilters, fechaCreacion: e.target.value })}
              />
            </label>
            <label>
              Fecha de inicio:
              <input
                type="date"
                name="fechaCreationDate"
                id="fechaCreationDate"
                className="filtros-form-input"
                value={selectedFilters.fechaCreationDate}
                onChange={(e) => setSelectedFilters({ ...selectedFilters, fechaCreationDate: e.target.value })}
              />
            </label>
            <label>
              Cantidad de solicitudes:
              <select
                className='filtros-form-select'
                name="pageSize" 
                id="pageSize" 
                value={selectedFilters.pageSize}
                onChange={handlePageSize} >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
              </select>
            </label>
          </form>
          <table className='tabla-solicitudes'>
            <thead className='tabla-cabecera-solicitudes'>
              <tr className='tabla-cabecera-solicitudes-titulos'>
                <th>Profesor solicitante</th>
                <th onClick={() => handleSortChange('estadoOrder')} className='sortable'>
                  Estado 
                  { selectedFilters.estadoOrder === 'undefined' ? 
                  <BsDashLg/> : 
                  selectedFilters.estadoOrder === 'asc' ? 
                  <FaArrowUp/> : 
                  <FaArrowDown/> }
                </th>
                <th>Ítems</th>
                <th onClick={() => handleSortChange('fechaCreationOrder')} className='sortable'>
                Fecha de creación pedido
                  { selectedFilters.fechaCreationOrder === 'undefined' ? 
                  <BsDashLg/> : 
                  selectedFilters.fechaCreationOrder === 'asc' ? 
                  <FaArrowUp/> : 
                  <FaArrowDown/> }
                </th>
                <th onClick={() => handleSortChange('fechaCreationDateOrder')} className='sortable'>
                  Fecha de inicio
                  { selectedFilters.fechaCreationDateOrder === 'undefined' ? 
                  <BsDashLg/> : 
                  selectedFilters.fechaCreationDateOrder === 'asc' ? 
                  <FaArrowUp/> : 
                  <FaArrowDown/> }
                </th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody className='tabla-cuerpo-solicitudes'>
              {pedidos && pedidos.length > 0 && pedidos.map((pedido, index) => (
                <tr key={index}>
                  <td>{pedido.usuario.nombre}</td>
                  <td>{estadoProfesorPanol(pedido.estado)}</td>
                  <td>
                    {pedido.items}
                    <img
                      src={eyeblue}
                      alt='icon-eye-blue'
                      id='Ojito'
                      onClick={() => handleShowItems(pedido)}
                    />
                  </td>
                  <td>{new Date(pedido.fecha_creacion).toLocaleDateString('es-ES')}</td>
                  <td>{(new Date(pedido.pedido_creation_date).toLocaleDateString('es-ES'))}</td>
                  <td>{obtenerAccion(pedido.estado, pedido)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Paginator totalPageCount={cantidadPaginas} currentPage={selectedFilters.page} handlePageChange={handlePageChange} />
        </>
      )}
      {mostrarComponente && <Showitems onClose={() => setMostrarComponente(false)} pedido={selectedPedido} handleShowCheckList={handleShowCheckList} />}
      {showCheckList && (
        <CheckListPanol
          mostrarCheckList={showCheckList}
          setMostrarCheckList={setShowCheckList}
          solicitud={selectedPedido}
          procesarPedido={procesar_pedido}
        />
      )}
     <ConfirmationModal
        isOpen={isConfirmModalOpen}
        onClose={closeConfirmModal}
        onConfirm={handleProcesarPedido}
        message="¿Estás seguro de que quieres procesar este pedido?"
      />
    </div>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  pedidos: state.Pedidos.pedidosUsuario,
  cantidadPaginas: state.Pedidos.totalPaginas
});

export default connect(mapStateToProps, {
  call_pedidos,
  procesar_pedido,
})(SolicitudList);