import axios from "axios";
import {
    GET_PEDIDOS_SUCCESS,
    GET_PEDIDOS_FAIL,
    PROCESAR_PEDIDO_SUCCESS,
    PROCESAR_PEDIDO_FAIL
} from './types'

export const call_pedidos = (params) => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
            }
        };

        try {
            const baseQuery = `?pageSize=${params.pageSize}&page=${params.page}`;
            const filterQuery = `&estado=${params.estado}&nroSolicitud=${params.nroSolicitud}&fechaCreacion=${params.fechaCreacion}&fechaCreationDate=${params.fechaCreationDate}&nombreCorreo=${params.nombreCorreo}&fechaCreacionOrder=${params.fechaCreacionOrder}&fechaCreationDateOrder=${params.fechaCreationDateOrder}&estadoOrder=${params.estadoOrder}`;

            const res = await axios.get(`${process.env.REACT_APP_API_URL}/pedidos/all/${baseQuery}${filterQuery}`, config);
            if (res.status === 200) {
                console.log(res.data)
                dispatch({

                    type: GET_PEDIDOS_SUCCESS,
                    payload: res.data
                });
            } else {

                dispatch({
                    type: GET_PEDIDOS_FAIL
                });
            }
        } catch (err) {
            dispatch({
                type: GET_PEDIDOS_FAIL
            });
        }
    }
}

export const procesar_pedido = (pedidoId, nuevoEstado, comentario = '') => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
            }
        };

        const body = {
            comentario: comentario,  // Pass the comment to the API
        };

        try {
            console.log(`Sending request to update pedido ${pedidoId} to state '${nuevoEstado}' with comment '${comentario}'`);  // Debug log
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/resolver-pedidos/?pedidoId=${pedidoId}&estado=${nuevoEstado}`,
                body,  // Send the body with comment
                config
            );

            if (res.status === 200) {
                console.log('Pedido processed successfully');  // Debug log
                dispatch({
                    type: PROCESAR_PEDIDO_SUCCESS,
                    payload: res.data
                });

                // Re-fetch the updated list of pedidos
                dispatch(call_pedidos({
                    pageSize: 25,
                    page: 1
                    // Add other params as needed
                }));
            } else {
                console.log('Failed to process pedido:', res.data);  // Debug log
                dispatch({
                    type: PROCESAR_PEDIDO_FAIL
                });
            }
        } catch (err) {
            console.error('Error processing pedido:', err.response ? err.response.data : err);  // Debug log
            dispatch({
                type: PROCESAR_PEDIDO_FAIL
            });
        }
    }
}
