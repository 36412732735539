import "./DropdownContent.css"

const DropdownContent = ({ children, open, toggle }) => {
    return (
        <div onClick={toggle} className={`dropdown-content ${open ? "content-open" : null}`}>
            {children}
        </div>
    )
}

export default DropdownContent