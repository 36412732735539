export default function Paginator({ currentPage, handlePageChange, totalPageCount }) {
  return (
    <section>
      <div className='paginator'>
        <button onClick={handlePageChange} className='paginator-button'>
          {'<'}
        </button>

        <div>
          <input
            type='number'
            value={currentPage}
            onChange={(ev) => {
              handlePageChange(parseInt(ev.target.value))
            }}
            className='paginator-input'
            style={{ width: '70px' }}
            min={1}
            max={totalPageCount}
          />
        </div>

        <button onClick={handlePageChange} className='paginator-button'>
          {'>'}
        </button>
      </div>
      <div className='paginator-page-selector'>
        <p>
          Página {currentPage} de {totalPageCount}
        </p>
      </div>
    </section>
  )
}
