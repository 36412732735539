import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listTalleres, createTaller, updateTaller, deleteTaller } from '../redux/actions/talleres';
import GestionarTallerModal from './GestionarTallerModal';
import '../css/GestionarTaller.css';

const GestionarTaller = () => {
  const dispatch = useDispatch();
  const { talleres, loading, error } = useSelector((state) => state.Taller);

  const [formValues, setFormValues] = useState({
    id: '',
    nombre: '',
    descripcion: '',
    fechaInicio: '',
    fechaTermino: '',
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    dispatch(listTalleres());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCreateTaller = (e) => {
    e.preventDefault();
    const { nombre, descripcion, fechaInicio, fechaTermino } = formValues;
    dispatch(createTaller(nombre, descripcion, fechaInicio, fechaTermino)).then(() => {
      dispatch(listTalleres()); // Reload the taller list
      closeModal();
    });
  };

  const handleUpdateTaller = (e) => {
    e.preventDefault();
    const { id, nombre, descripcion, fechaInicio, fechaTermino } = formValues;
    dispatch(updateTaller(id, nombre, descripcion, fechaInicio, fechaTermino)).then(() => {
      dispatch(listTalleres()); // Reload the taller list
      closeModal();
    });
  };

  const openCreateModal = () => {
    setIsEditMode(false);
    setFormValues({
      id: '',
      nombre: '',
      descripcion: '',
      fechaInicio: '',
      fechaTermino: '',
    });
    setIsModalOpen(true);
  };

  const openEditModal = (taller) => {
    setIsEditMode(true);
    setFormValues({
      id: taller.id,
      nombre: taller.nombre,
      descripcion: taller.descripcion,
      fechaInicio: taller.fecha_inicio,
      fechaTermino: taller.fecha_termino,
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
    setFormValues({
      id: '',
      nombre: '',
      descripcion: '',
      fechaInicio: '',
      fechaTermino: '',
    });
  };

  const handleDeleteTaller = (id) => {
    dispatch(deleteTaller(id)).then(() => {
      dispatch(listTalleres());
    });
  };

  const sortedTalleres = talleres ? [...talleres].sort((a, b) => a.nombre.localeCompare(b.nombre)) : [];

  return (
    <div className="GT-container">
      <div className="GT-inner-container">
        <h2 className="GT-title">Gestionar Talleres</h2>

        {loading && <p className="GT-loading">Loading...</p>}
        {error && <p className="GT-error">Error: {error}</p>}

        <button className="GT-create-button" onClick={openCreateModal}>
          Crear Taller
        </button>

        <GestionarTallerModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmit={isEditMode ? handleUpdateTaller : handleCreateTaller}
          formValues={formValues}
          handleInputChange={handleInputChange}
          isEditMode={isEditMode}
        />

        <h3 className="GT-subtitle">Lista de Talleres</h3>
        <ul className="GT-taller-list">
          {sortedTalleres.map((taller) => (
            <li key={taller.id} className="GT-taller-item">
              <span className="GT-taller-info">{taller.nombre}</span>
              <div className="GT-taller-actions">
                <button className="GT-edit-button" onClick={() => openEditModal(taller)}>Editar</button>
                <button className="GT-delete-button" onClick={() => handleDeleteTaller(taller.id)}>Eliminar</button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default GestionarTaller;
