import React, { useState, useEffect } from "react";
import "../css/Navbar.css";
import userLogo from "../images/user.png";
import lupaBackground from "../images/Fondolupita.png";
import lupaIcon from "../images/LUPA2.png";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { get_productos ,setSearchTerm } from "../redux/actions/productos";
import ProductoListaCarrito from "./ProductoListaCarrito ";

const Navbar = ({
  get_productos,
  productos,
  user,
  setSearchTerm
}) => {

  const location = useLocation();
  const [searchTerm1, setSearchTerm1] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [get_productos]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSearchTerm(event.target.value);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm1(value);
    setSearchTerm(value);
    if (value) {
      const filteredProducts = productos.filter(producto =>
        producto.equipo.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredProducts);
    } else {
      setSuggestions([]);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setTimeout(() => setIsFocused(false), 200); // Para permitir la selección del elemento de la lista
  };

  const pathsToShowSearch = ["/carroproductos", "/ListaProductos"];
  const shouldDisplaySearch = pathsToShowSearch.includes(location.pathname);

  return (
    <nav className="navbar">
      <section className="mt-0">
      </section>
      {shouldDisplaySearch && (
        <section className="search-section mt-0">
          <div className="search-container">
            <input
              type="text"
              value={searchTerm1}
              onChange={handleSearchChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={handleKeyPress}
              className="search-input"
              placeholder={isFocused ? "" : "¿Qué estás buscando?"}
              style={{ marginLeft: "10px" }} // Añade margen izquierdo al input
            />
            <img
              src={lupaBackground}
              alt="lupa background"
              className="lupa-background"
            />
            <img src={lupaIcon} alt="lupa icon" className="lupa-icon" />
            {isFocused && suggestions.length > 0 && (
              <ul className="autocomplete-suggestions">
                {suggestions.map((producto) => (
                  <li key={producto.id} className="suggestion-item">
                    {producto.equipo}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </section>
      )}
      <section className="informacion_navbar mt-0">
        {user && user !== null && user !== undefined ? (
          <span className="text-white mr-3 align-text-top">{user.nombre}</span>
        ) : (
          <Link to="/">
            <span className="text-white mr-3">Iniciar sesión</span>
          </Link>
        )}
        <img className="img-logo" src={userLogo} alt="logo user" />
        <ProductoListaCarrito/>
      </section>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  user: state.Auth.user,
  productos: state.Productos.productos,
});
const mapDispatchToProps = {
  get_productos,
  setSearchTerm,
};
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
