import React, { useState, useEffect } from 'react';
import { login } from '../redux/actions/auth';
import { Navigate } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const LoginForm = ({ login, loading, error }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [activated, setActivated] = useState(false);
    const [submitError, setSubmitError] = useState('');

    const { email, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async e => {
        e.preventDefault();
        if (!email || !password) {
            setSubmitError('Por favor, ingresa tu email y contraseña.');
            return;
        }
        try {
            const result = await login(email, password);
            if (result.success) { 
                setActivated(true);
                window.scrollTo(0, 0);
            } else {
                setSubmitError('Credenciales inválidas. Por favor, inténtalo de nuevo.');
            }
        } catch (err) {
            setSubmitError('Error al iniciar sesión. Por favor, inténtalo de nuevo.');
        }
    };

    if (activated) {
        return <Navigate to='/landing' />;
    }

    return (
        <form onSubmit={onSubmit} className="formulario text-center">
            <h2 className="formulario-h2">Inicio de sesión</h2>
            {(submitError || error) && <p style={{ color: 'red' }}>{submitError || error}</p>}
            <div className="form-group">
                <input id='email' name='email' type="email" className="formulario-input my-2" placeholder="Email" aria-describedby="emailHelp" value={email} onChange={onChange} />
            </div>
            <div className="form-group">
                <input id="password" name="password" type="password" className="formulario-input" placeholder="Contraseña" onChange={onChange} />
            </div>
            <div className="my-4 d-flex justify-content-center">

    <button style={{fontFamily:'Nunito'}} type="submit" className="botoncito btn-iniciar-sesion">Iniciar sesión</button>
</div>
            <Link to="/forgot_password">¿Olvidaste tu contraseña?</Link>
        </form>
    );
};

const mapStateToProps = state => ({
    loading: state.Auth.loading,
    error: state.Auth.error
});

export default connect(mapStateToProps, { login })(LoginForm);
